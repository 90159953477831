import React, { Component } from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import {
  injectStripe,
} from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import * as uuid from 'uuid/v4';
import getSymbolFromCurrency from 'currency-symbol-map';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import CardSection from '../formSections/CardSection'
import AddressSection from '../formSections/AddressSection'
import CircularProgress from '@material-ui/core/CircularProgress';
import NameSection from '../formSections/NameSection'
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from '../messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper:{
    minWidth: "80%",
    zIndex: '1000'
  },
  paperIframe:{
    minWidth: "80%",
    zIndex: '1000',
    boxShadow: "none",
    paddingBottom: "25%"
  },
  button:{
    minWidth: "60%",
    marginTop: "5%"
  },
  textField:{
    marginTop: "1%"
 },
   textFieldHalf:{
      width: '30%',
      float:"left"
  },
  textFieldQuarter:{
     width: '25%',
     float:"left"
 },
  textFieldInLine:{
    marginTop: "1%"
  },
interval:{
  fontSize: "12px"
}
});


class CheckoutForm extends React.Component {
  constructor(props){
  super(props);
  this.state ={
    logo:`https://s3-us-west-2.amazonaws.com/instapayments/${this.props.match.params.orgId}_${this.props.match.params.org}`,
    form: {},
    line_items: [],
    custom_fields: [],
    metadata:{},
    height: window.innerHeight,
    width: window.innerWidth,
    firstLoadAttempted: false
  }

}
updateDimensions = () => {
    this.setState({width: window.innerWidth, height: window.innerHeight});
}

componentWillUnmount() {
     window.removeEventListener("resize", this.updateDimensions);
 }

componentDidMount(){
  window.addEventListener("resize", this.updateDimensions);
  this.fetchForm();
  this.setState({
    idempotency_keys: {
      create_customer: uuid(),
      create_order: uuid(),
      pay_order: uuid()
    }, //this will render once per page load
  })
}

fetchForm = () => {
  var self = this;
  let requestUrl = `${apiBaseUrl}/v1/form/forms/${self.props.match.params.org}/${self.props.match.params.orgId}/${self.props.match.params.formId}`;
  if (!self.props.match.params.org || !self.props.match.params.orgId) {
    requestUrl = `${apiBaseUrl}/v1/form/forms/${self.props.match.params.formId}`
  }
  axios.get(requestUrl)
  .then(function (response) {


    if (!response.data.form) {
      window.location.href = "/notfound";
    } else if (!response.data.has_subscription) {
      window.location.href = "/canceled";
    }else{

      if (response.data.form && !response.data.form.currency) {
        response.data.form.currency = "usd"
      }

      self.setState({
        form: response.data.form,
        custom_fields: response.data.form.custom_fields,
        line_items: response.data.form.line_items,
        org_handle: response.data.org_handle,
        org_id: response.data.org_id,
        ...response.data.branding,
        has_subscription: response.data.has_subscription,
        firstLoadAttempted: true
      });
    }

  })
  .catch((error) => {
    self.setState({
      submitting: false,
      snackOpen: true,
      snackVariant:"error",
      snackMessage:"Error loading form."
    })
    console.log(error);
  });
}

handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handleCustomFieldChange = name => event => {
  const existingMetaData = {...this.state.metadata}
  existingMetaData[name] = event.target.value; //remake the metadata object with a new value
  this.setState({
    metadata: existingMetaData
  });
};

handlePassChange = (name, value) => {
  this.setState({
    [name]: value,
  });
};

handleSubmit = (ev) => {
  ev.preventDefault();
  var self = this;
  if (this.state.cardCompleted) {
  this.setState({
    submitting: true
  })

  let otherData = {
    email: this.state.email,
    idempotency_keys: this.state.idempotency_keys,
    name: this.state.name,
    address: this.state.address,
    city: this.state.city,
    country: this.state.country,
    state: this.state.state,
    postal_code: this.state.postal_code,
    zip: this.state.postal_code,
    metadata: this.state.metadata
  }
  if (this.state.coupon) {
    otherData.coupon = this.state.coupon
  }
  this.props.stripe.createToken({
    name: this.state.name,
    address_line1: this.state.address,
    address_city: this.state.city,
    address_country: this.state.country,
    address_state: this.state.state,
    address_zip: this.state.postal_code
  }).then(({token}) => {
    if (token) {
      self.postToken(token, otherData)
    }else{
      this.setState({
        submitting: false,
        snackOpen: true,
        snackVariant:"error",
        snackMessage:"Please check your card"
      })
    }
  });
}else{
  this.setState({
    submitting: false,
    snackOpen: true,
    snackVariant:"error",
    snackMessage:"Please check your card"
  })
}
};

postToken = (token, otherData) =>{
  var self = this;

axios.post(`${apiBaseUrl}/v1/form/forms/onetime/${self.state.org_id}/${self.props.match.params.formId}`,
  {
    token: token.id,
    full_token: token,
    email: otherData.email,
    idempotency_keys: otherData.idempotency_keys,
    name: otherData.name,
    address: otherData.address,
    city: otherData.city,
    country: otherData.country,
    state: otherData.state,
    postal_code: otherData.postal_code,
    zip: otherData.postal_code,
    metadata: otherData.metadata,
    coupon: otherData.coupon
  })
  .then(function (response) {

    self.setState({
      submitting: false
    })
    let url = "/success"
    if (self.state.form.redirect_url) {
      function addhttp(url) {
        if (!/^(f|ht)tps?:\/\//i.test(url)) {
          url = "http://" + url;
        }
        return url;
      }
      url = addhttp(self.state.form.redirect_url);
    }
    window.top.location.href = url;
  })
  .catch(function (error) {
    self.setState({
      submitting: false
    })
    let errorResponse = "Error processing. Please check your card and other fields."
    if (error.response && error.response.data && error.response.data.message) {
      errorResponse = error.response && error.response.data && error.response.data.message
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: errorResponse
      });
    }else{
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: errorResponse
      });
    }
    console.log(error);
  });
}

fetchDiscount = () => {
  var self = this;
  if (self.state.coupon && self.state.coupon.length > 1) {
  axios.get(`${apiBaseUrl}/api/discounts/${this.state.org_id}?coupon=${self.state.coupon}`)
  .then(function (response) {
    self.setState({
      coupon_response:response.data.coupon,
      coupon_invalid: false
    });
  })
  .catch(function (error) {
    self.setState({
      snackOpen: true,
      snackVariant:"error",
      snackMessage: "That coupon looks invalid.",
      coupon_invalid: true,
      coupon_response: undefined
    });
  });
}else{
  self.setState({
    coupon_invalid: false,
    coupon_response: undefined
  });
}
}

handleCardChange = (event) =>{

if (event.error) {
  this.setState({
    snackOpen: true,
    snackVariant:"error",
    snackMessage:event.error.message
  });
}else if(event.error === undefined){
  this.setState({
    snackOpen: false
  });
}

if(event.complete){
  this.setState({
    cardCompleted: true
  });
}
}

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

  render() {
    const { classes } = this.props;
    const lineItems = [];
    let total = 0;
    const customFields = [];

  for (let item of this.state.line_items) {

    total = total + item.selected_sku.price
    lineItems.push(
      <tr className="line-item line-product">
            <td>{item.selected_sku.attributes.name}</td>
            <td>
            {`${getSymbolFromCurrency(this.state.form.currency)}${(item.selected_sku.price/100).toFixed(2)}`}
            </td>
          </tr>
        )

  }


if (this.state.form && this.state.form.custom_fields) {
  for (let custom_field of this.state.custom_fields) {
    customFields.push(
      <TextField
      key={custom_field._id}
      label={custom_field.name}
      fullWidth
      className={classes.textField}
      required={custom_field.required}
      onChange={this.handleCustomFieldChange(custom_field.name)}
      />
    )
  }
}





  if (this.state.form && this.state.form.taxes && this.state.form.taxes.active) {
    lineItems.push(
      <tr className="line-item line-product">
            <td>Taxes {Math.round(this.state.form.taxes.percent* 100) / 100}%</td>
            <td>{getSymbolFromCurrency(this.state.form.currency)}{(total * (Number(this.state.form.taxes.percent)/100/100)).toFixed(2)}</td>
          </tr>
        )

    total = total + (total * (Number(this.state.form.taxes.percent)/100)) //this must be last so the tax line item calculates the right amount
  }


  if (this.state.coupon_response) {
    let discount_amount = 0;
    if (this.state.coupon_response.amount_off) {
      discount_amount = this.state.coupon_response.amount_off;
        total = total - this.state.coupon_response.amount_off;
    }

    if (this.state.coupon_response.percent_off) {
      discount_amount = total * (this.state.coupon_response.percent_off/100);
      total = total - discount_amount;
    }

    lineItems.push(
      <tr className="line-item line-product">
            <td>Coupon</td>

            <td>{`- ${getSymbolFromCurrency(this.state.form.currency)}${((Number(discount_amount)/100)).toFixed(2)}`}
            <span className={classes.interval}>{" " + this.state.coupon}</span>
            </td>
          </tr>
        )
  }

    return (
      <div className={"child"}>

      {this.state.firstLoadAttempted ?
      <div>

      <Paper className={this.state.width < 500 ? classes.paperIframe : classes.paper}>
      <Grid container justify="center">
      <div style={{maxHeight: "3%", marginTop: "1%"}}>
      {this.state.form && this.state.form.custom_logo && this.state.form.custom_logo.active ?
            <img src={this.state.form.custom_logo.custom_logo_url} style={{maxHeight: "120px"}} alt="logo"/>
      : this.state.logo ? 
      <img src={this.state.logo} style={{maxHeight: "120px"}} alt="logo"/>
      :
      null
      }
      </div>
      </Grid>
      <Grid container justify="center">

      <div id="line-items">
        <table className="item-table2">
        <tbody>
        {lineItems}

          </tbody>
          <tfoot>
          <tr className="line-item total">
            <td><strong>TOTAL</strong></td>

            <td><span id="checkout-total">{getSymbolFromCurrency(this.state.form.currency)}{(total/100).toFixed(2)}</span></td>
          </tr>
          </tfoot>
        </table>
        <Divider/>
      </div>
      </Grid>
      <form className={"form"} onSubmit={this.handleSubmit}>
      <Grid container spacing={0} justify="center">

      <NameSection {...this.props} changeInput={this.handlePassChange}/>
      {this.state.form.hide_address ?
        null
        :
      <AddressSection {...this.props} changeInput={this.handlePassChange}/>
      }
      {this.state.form && this.state.form.coupon_enabled ?
        <TextField
        label="Promo Code"
        fullWidth
        error={this.state.coupon_invalid}
        onBlur={this.fetchDiscount}
        className={classes.textField}
        onChange={this.handleChange('coupon')}
        />
        :
        null
      }

      {customFields}
      <Grid item xs={12}>
      <CardSection {...this.props} onCardChange={this.handleCardChange}/>
      {this.state.form && this.state.form.terms && this.state.form.terms.active ?
        <div style={{float: "left"}}>
        <FormControlLabel
        control={
          <Checkbox
          required
          checked={this.state.termsAccepted}
          onChange={()=> this.setState({termsAccepted: !this.state.termsAccepted})}
          value=""
          color={this.state.primary_color || "#814ff8"}
          />
        }
        label={
          <div>
            <p>I Accept the <a rel="noopener noreferrer" target="_blank" href={this.state.form.terms.link}>Terms and Conditions</a>
            </p>
          </div>}
        />
        </div>
        :
        null
      }
      </Grid>
        <Button
        variant="contained"
        color="primary"
        fullWidth={this.state.width < 500 ? true : false}
        style={{
              backgroundColor: this.state.primary_color || "#814ff8",
              position: this.state.width < 500 ? "fixed" : undefined,
              bottom: this.state.width < 500 ? 0 : undefined,
              padding: this.state.width < 500 ? "7%" : undefined
              }}
        className={classes.button}
        disabled={this.state.submitting}
        type="submit"
        >
          { this.state.submitting ? <CircularProgress className={classes.progress} /> : "Complete Checkout"}
        </Button>
        </Grid>
      </form>

      </Paper>
      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={this.state.snackOpen}
      autoHideDuration={2000}
      onClose={this.handleSnackClose}
      >
      <MySnackbarContentWrapper
      variant={this.state.snackVariant}
      message={this.state.snackMessage}
      onClose={this.handleSnackClose}
      />
      </Snackbar>
      </div>
        :
        <Grid container justify={"center"}>
        <div className={classes.loader}>
        <CircularProgress size={100} className={classes.progress} />
        </div>
        </Grid>
      }
        </div>
    );
  }
}

export default withStyles(styles)(injectStripe(CheckoutForm));
