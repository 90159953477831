import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
const styles = theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    marginTop: "5%",
    padding: "5%"
  },
  halfWidthText: {
    display: "inline"
  },
  submit: {
    width: "35%",
    marginTop: "3%"
  }
});

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      phone: "",
      email: ""
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const formValues = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone: this.state.phone,
      email: this.state.email
    };
    this.props.onCustomerFormSubmit(formValues);
  };

  render() {
    const { classes } = this.props;

    return (
        <Paper className={classes.paper}>
          <Typography variant="h5">Your Info</Typography>
          <form className={classes.form} onSubmit={this.handleFormSubmit}>
          <Grid container spacing={8}>
          <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  required
                  onChange={this.handleChange("first_name")}
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  autoComplete="given-name"
                  autoFocus
                />
              </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  required
                  onChange={this.handleChange("last_name")}
                  fullWidth
                  id="last_name"
                  label="Last Name"
                  name="last_name"
                  autoComplete="family-name"
                  autoFocus
                />
            </Grid>
            </Grid>

            <TextField
              margin="normal"
              required
              onChange={this.handleChange("phone")}
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              autoComplete="tel"
              autoFocus
            />

            <TextField
              margin="normal"
              required
              onChange={this.handleChange("email")}
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Continue
              </Button>
            </Grid>
          </form>
        </Paper>
    );
  }
}
export default withStyles(styles)(CustomerForm);
