import React, { Component } from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { injectStripe } from "react-stripe-elements";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import CardSection from "../formSections/CardSection";
import AddressSection from "../formSections/AddressSection";
import CircularProgress from "@material-ui/core/CircularProgress";
import NameSection from "../formSections/NameSection";
import Snackbar from "@material-ui/core/Snackbar";
import ordinal from "ordinal";
import qs from "query-string";
import MySnackbarContentWrapper from "../messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minWidth: "80%",
    zIndex: "1000",
  },
  paperIframe: {
    minWidth: "80%",
    zIndex: "1000",
    boxShadow: "none",
    paddingBottom: "25%",
  },
  button: {
    minWidth: "60%",
    marginTop: "5%",
  },
  textField: {
    marginTop: "1%",
  },
  textFieldHalf: {
    width: "30%",
    float: "left",
  },
  textFieldQuarter: {
    width: "25%",
    float: "left",
  },
  textFieldInLine: {
    marginTop: "1%",
  },
  interval: {
    fontSize: "12px",
  },
});

function addhttp(url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
}

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: `https://s3-us-west-2.amazonaws.com/instapayments/${
        this.props.match.params.orgId
      }_${this.props.match.params.org}`,
      form: {},
      line_items: [],
      custom_fields: [],
      metadata: {},
      height: window.innerHeight,
      width: window.innerWidth,
      firstLoadAttempted: false,
      coupon: qs.parse(this.props.location.search).discount,
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  showNotification = (
    message = "no message recieved in notification function",
    type = "error"
  ) => {
    this.setState({
      snackOpen: true,
      snackVariant: type,
      snackMessage: message,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  async componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    var self = this;

    await this.setState(this.props.passedData);
    if (this.state.coupon) {
      this.fetchDiscount();
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCustomFieldChange = (name) => (event) => {
    const existingMetaData = { ...this.state.metadata };
    existingMetaData[name] = event.target.value; //remake the metadata object with a new value
    this.setState({
      metadata: existingMetaData,
    });
  };

  handlePassChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  /*The function where the form is initially submitted. 
Creates a token and passes that to the appropriate function.*/
  handleSubmit = (ev) => {
    ev.preventDefault();
    var self = this;
    if (this.state.cardCompleted) {
      this.setState({
        submitting: true,
      });

      let otherData = {
        email: this.state.email,
        name: this.state.name,
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        postal_code: this.state.postal_code,
        zip: this.state.postal_code,
        metadata: this.state.metadata,
      };
      if (this.state.coupon) {
        otherData.coupon = this.state.coupon;
      }
      this.props.stripe
        .createToken({
          name: this.state.name,
          address_line1: this.state.address,
          address_city: this.state.city,
          address_country: this.state.country,
          address_state: this.state.state,
          address_zip: this.state.postal_code,
        })
        .then(({ token }) => {
          if (token) {
            if (self.state.customerId && self.state.invoiceId) {
              self.handleIncomplete(token, otherData);
            } else {
              self.postToken(token, otherData);
            }
          } else {
            this.setState({
              submitting: false,
              snackOpen: true,
              snackVariant: "error",
              snackMessage: "Please check your card",
            });
          }
        });
    } else {
      this.setState({
        submitting: false,
        snackOpen: true,
        snackVariant: "error",
        snackMessage: "Please check your card",
      });
    }
  };

  /*Submit the token to the backend*/
  postToken = (token, otherData) => {
    var self = this;
    var lineItems = [];

    for (const item of self.state.form.line_items) {
      lineItems.push({
        plan:
          self.props.testMode && item.selected_plan.test_id
            ? item.selected_plan.test_id
            : item.selected_plan.id,
        quantity: 1,
      });
    }
    axios
      .post(
        `${apiBaseUrl}/v2/form/forms/${self.props.match.params.formId}${
          self.props.testMode ? "?test=true" : ""
        }`,
        {
          token: token.id,
          full_token: token,
          email: otherData.email,
          line_items: lineItems, //pass the plans and quantity
          name: otherData.name,
          address: otherData.address,
          city: otherData.city,
          country: otherData.country,
          state: otherData.state,
          postal_code: otherData.postal_code,
          zip: otherData.postal_code,
          metadata: otherData.metadata,
          coupon: otherData.coupon,
        }
      )
      .then(function(response) {
        self.handleSubmitSuccess(response);
      })
      .catch(function(error) {
        self.handleCardDeclineResponse(error);
      });
  };

  /*Handles a card decline with new payment intent API */
  handleIncomplete = (token, otherData) => {
    var self = this;
    axios
      .post(`${apiBaseUrl}/v2/form/forms/transaction/incomplete`, {
        token: token.id,
        org: self.state.org_id,
        customerId: self.state.customerId,
        invoiceId: self.state.invoiceId,
        full_token: token,
        email: otherData.email,
        name: otherData.name,
        address: otherData.address,
        city: otherData.city,
        country: otherData.country,
        state: otherData.state,
        postal_code: otherData.postal_code,
        zip: otherData.postal_code,
        metadata: otherData.metadata,
        coupon: otherData.coupon,
      })
      .then(function(response) {
        self.handleSubmitSuccess(response);
      })
      .catch(function(error) {
        self.handleCardDeclineResponse(error);
      });
  };

  /*Triggers the extra authentication for use cases that need SCA*/
  handleSCA = (requestBody) => {
    var self = this;
    self.showNotification("Additional verification is required.", "warning");
    self.props.stripe
      .handleCardPayment(requestBody.pi_secret, self.state.cardElement, {
        payment_method_data: {
          billing_details: {
            name: self.state.name,
          },
        },
      })
      .then(function(result) {
        console.log(result);
        if (
          result.paymentIntent &&
          result.paymentIntent.status === "succeeded"
        ) {
          console.log("the payment succeeded");
          self.showNotification("The payment was successful.", "success");
          let url = "/success";
          let queryParams = `?customer_id=${
            requestBody.customer_id
          }&subscription_id=${requestBody.subscription_id}&plan_id=${
            requestBody.plan
          }&product_id=${requestBody.product}`;

          if (self.state.form.redirect_url) {
            url = addhttp(self.state.form.redirect_url);
          }

          window.top.location.href = url + queryParams;
        } else if (result.error) {
          console.log("ERROR AFTER SCA", result);
          self.setState({
            submitting: false,
          });
          if (result.error && result.error.message) {
            self.showNotification(result.error.message);
          } else {
            self.showNotification(
              "There was an error after additional verification."
            );
          }
        } else {
          self.setState({
            submitting: false,
          });
          self.showNotification(
            "Error handling additional verification response."
          );
        }
        // Handle result.error or result.paymentIntent
      })
      .catch((err) => {
        self.setState({
          submitting: false,
        });
        self.showNotification("Error triggering response.");
        console.log(err);
      });
  };
  /*Deals with the various ways an error can be returned*/
  handleCardDeclineResponse = (error) => {
    var self = this;
    console.log("ERROR STATUS", error.response);

    let errorResponse =
      "Error processing. Please check your card and other fields.";
    if (error.response && error.response.data && error.response.data.message) {
      errorResponse =
        error.response && error.response.data && error.response.data.message;

      const requestBody = error.response.data;
      if (
        error.response.status === 406 &&
        requestBody.pi_status === "requires_action"
      ) {
        self.handleSCA(requestBody); //handle the additional verification if our backend returns 406
      } else if (
        error.response.status === 406 &&
        requestBody.pi_status === "requires_payment_method"
      ) {
        /*save the customer ID and invoice ID to state and ask for a new card*/
        self.setState({
          submitting: false,
          customerId: error.response.data.customer_id,
          invoiceId: error.response.data.invoice_id,
        });
        self.showNotification(errorResponse);
      } else {
        self.setState({
          submitting: false,
        });
        self.showNotification(errorResponse);
      }
      console.log(error);
    } else {
      /*This is a more generic card failure*/
      self.setState({
        submitting: false,
      });
      self.showNotification(errorResponse);
    }
  };
  /*Triggered when a charge is successfully completed. Redirects to the url set by the business.*/
  handleSubmitSuccess = (response) => {
    var self = this;
    self.setState({
      submitting: false,
    });
    let url = "/success";
    let queryParams = `?customer_id=${
      response.data.customer_id
    }&subscription_id=${response.data.subscription_id}&plan_id=${
      response.data.plan
    }&product_id=${response.data.product}`;
    if (self.state.form.redirect_url) {
      function addhttp(url) {
        if (!/^(f|ht)tps?:\/\//i.test(url)) {
          url = "http://" + url;
        }
        return url;
      }
      url = addhttp(self.state.form.redirect_url);
    }
    //window.location.href = url;
    window.top.location.href = url + queryParams;
  };

  fetchDiscount = () => {
    var self = this;
    if (self.state.coupon && self.state.coupon.length > 1) {
      axios
        .get(
          `${apiBaseUrl}/v1/form/discounts/${self.state.org_id}?coupon=${
            self.state.coupon
          }`
        )
        .then(function(response) {
          self.setState({
            coupon_response: response.data.coupon,
            coupon_invalid: false,
          });
        })
        .catch(function(error) {
          self.setState({
            coupon_invalid: true,
            coupon_response: undefined,
          });
          self.showNotification("That coupon looks invalid.");
        });
    } else {
      self.setState({
        coupon_invalid: false,
        coupon_response: undefined,
      });
    }
  };

  handleCardReady = (element) => {
    this.setState({ cardElement: element });
  };

  handleCardChange = (event) => {
    if (event.error) {
      this.showNotification(event.error.message);
    } else if (event.error === undefined) {
      this.setState({
        snackOpen: false,
      });
    }

    if (event.complete) {
      this.setState({
        cardCompleted: true,
      });
    }
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  render() {
    const { classes } = this.props;
    const lineItems = [];
    let total = 0;
    const customFields = [];
    const currency = getSymbolFromCurrency(this.state.form.currency);
    let first_line_item = this.state.line_items[0];

    for (let item of this.state.line_items) {
      let trialText = "";
      let lineItemDesc = ` (every ${
        item.selected_plan.interval_count === 1
          ? ""
          : item.selected_plan.interval_count
      } ${item.selected_plan.interval}${trialText})`;

      if (
        this.state.form.hide_0_line_items === true &&
        item.selected_plan.amount === 0
      ) {
      } else {
        trialText = first_line_item.selected_plan.trial_period_days
          ? " after " +
            first_line_item.selected_plan.trial_period_days +
            " day trial"
          : "";
      }

      if (
        this.state.form.billing_anchor_date &&
        this.state.form.billing_anchor_date.active &&
        this.state.form.billing_anchor_date.prorated === false
      ) {
        lineItemDesc = ` starting on the ${ordinal(
          this.state.form.billing_anchor_date.date_number
        )}`;
      } else {
        total = total + item.selected_plan.amount * item.quantity;
      }
      //console.log("PRICE", item.selected_plan.amount, "QUANTITY", item.quantity)
      lineItems.push(
        <tr className="line-item line-product">
          <td>{item.name}</td>

          <td>
            {`${currency}${(item.selected_plan.amount / 100).toFixed(2)}`}
            <span className={classes.interval}>{lineItemDesc}</span>
          </td>
        </tr>
      );
    }

    if (
      this.state.form &&
      this.state.form.setup_fee &&
      this.state.form.setup_fee.active
    ) {
      total = total + Number(this.state.form.setup_fee.amount_in_cents);
      lineItems.push(
        <tr className="line-item line-product">
          <td>Initial Fee</td>
          <td>{`${currency}${(
            Number(this.state.form.setup_fee.amount_in_cents) / 100
          ).toFixed(2)}`}</td>
        </tr>
      );
    }
    if (this.state.form && this.state.form.custom_fields) {
      for (let custom_field of this.state.custom_fields) {
        if (custom_field.field_type === "dropdown") {
          customFields.push(
            <TextField
              select
              label={custom_field.name}
              fullWidth
              required={custom_field.required}
              value={this.state.metadata[custom_field.name]}
              onChange={this.handleCustomFieldChange(custom_field.name)}
              SelectProps={{
                native: true,
              }}
            >
              <option value="" />
              {custom_field.values.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </TextField>
          );
        } else if (custom_field.field_type === "text") {
          customFields.push(
            <TextField
              key={custom_field._id}
              label={custom_field.name}
              fullWidth
              className={classes.textField}
              required={custom_field.required}
              onChange={this.handleCustomFieldChange(custom_field.name)}
            />
          );
        }
      }
    }

    if (
      this.state.form &&
      this.state.form.taxes &&
      this.state.form.taxes.active
    ) {
      lineItems.push(
        <tr className="line-item line-product">
          <td>
            Taxes {Math.round(this.state.form.taxes.percent * 100) / 100}%
          </td>
          <td>
            {currency}
            {(
              total *
              (Number(this.state.form.taxes.percent) / 100 / 100)
            ).toFixed(2)}
          </td>
        </tr>
      );

      total = total + total * (Number(this.state.form.taxes.percent) / 100); //this must be last so the tax line item calculates the right amount
    }

    if (
      this.state.form &&
      this.state.form.installment_plan &&
      this.state.form.installment_plan.active
    ) {
      lineItems.push(
        <tr className="line-item line-product">
          <td>Expiration</td>
          <td>
            <span
              className={classes.interval}
            >{`Expires after a total of ${currency}${this.state.form
              .installment_plan.amount_in_cents / 100} is charged.`}</span>
          </td>
        </tr>
      );
    }

    if (this.state.coupon_response) {
      let discount_amount = 0;
      if (this.state.coupon_response.amount_off) {
        discount_amount = this.state.coupon_response.amount_off;
        total = total - this.state.coupon_response.amount_off;
      }

      if (this.state.coupon_response.percent_off) {
        discount_amount =
          total * (this.state.coupon_response.percent_off / 100);
        total = total - discount_amount;
      }

      lineItems.push(
        <tr className="line-item line-product">
          <td>Coupon</td>

          <td>
            {`- ${currency}${(Number(discount_amount) / 100).toFixed(2)}`}
            <span className={classes.interval}>{" " + this.state.coupon}</span>
          </td>
        </tr>
      );
    }

    /*if (first_line_item && first_line_item.selected_plan && first_line_item.selected_plan.trial_period_days) {
    lineItems.push(
    <tr className="line-item line-product">
    <td>
    Free Trial
    </td>
    <td>
    {first_line_item.selected_plan.trial_period_days}
    <span>
    {` Days`}
    </span>
    </td>
  </tr>
    )
  }*/

    return (
      <div className={"child"}>
        {this.state.firstLoadAttempted ? (
          <div>
            <Paper
              className={
                this.state.width < 500 ? classes.paperIframe : classes.paper
              }
            >
              <Grid container justify="center">
                <div style={{ maxHeight: "3%", marginTop: "1%" }}>
                  {this.state.form &&
                  this.state.form.custom_logo &&
                  this.state.form.custom_logo.active ? (
                    <img
                      src={this.state.form.custom_logo.custom_logo_url}
                      style={{ maxHeight: "120px" }}
                      alt="logo"
                    />
                  ) : this.state.logo ? (
                    <img
                      src={this.state.logo}
                      style={{ maxHeight: "120px" }}
                      alt="logo"
                    />
                  ) : null}
                </div>
              </Grid>
              {this.state.form.hide_0_line_items && total == 0 ? null : (
                <Grid container justify="center">
                  <div id="line-items">
                    <table className="item-table2">
                      <tbody>{lineItems}</tbody>
                      <tfoot>
                        <tr className="line-item total">
                          <td>
                            <strong>Today's Total</strong>
                          </td>

                          <td>
                            <span id="checkout-total">
                              {first_line_item.selected_plan.trial_period_days
                                ? currency + "0.00"
                                : currency + (total / 100).toFixed(2)}
                            </span>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                    <Divider />
                  </div>
                </Grid>
              )}
              <form className={"form"} onSubmit={this.handleSubmit}>
                <Grid container spacing={0} justify="center">
                  <NameSection
                    {...this.props}
                    custom_labels={this.state.form.custom_labels}
                    changeInput={this.handlePassChange}
                  />
                  {this.state.form.hide_address ? null : (
                    <AddressSection
                      {...this.props}
                      custom_labels={this.state.form.custom_labels}
                      changeInput={this.handlePassChange}
                    />
                  )}
                  {this.state.form && this.state.form.coupon_enabled ? (
                    <TextField
                      label="Promo Code"
                      fullWidth
                      error={this.state.coupon_invalid}
                      onBlur={this.fetchDiscount}
                      className={classes.textField}
                      onChange={this.handleChange("coupon")}
                    />
                  ) : null}

                  {customFields}
                  <Grid item xs={12}>
                    <CardSection
                      {...this.props}
                      onCardReady={this.handleCardReady}
                      onCardChange={this.handleCardChange}
                    />
                    {this.state.form &&
                    this.state.form.terms &&
                    this.state.form.terms.active ? (
                      <div style={{ float: "left" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              required
                              checked={this.state.termsAccepted}
                              onChange={() =>
                                this.setState({
                                  termsAccepted: !this.state.termsAccepted,
                                })
                              }
                              value=""
                              color={this.state.primary_color || "#814ff8"}
                            />
                          }
                          label={
                            <div>
                              <p>
                                I Accept the{" "}
                                <a
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  href={this.state.form.terms.link}
                                >
                                  Terms and Conditions
                                </a>
                              </p>
                            </div>
                          }
                        />
                      </div>
                    ) : null}
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={this.state.width < 500 ? true : false}
                    style={{
                      backgroundColor: this.state.primary_color || "#814ff8",
                      position: this.state.width < 500 ? "fixed" : undefined,
                      bottom: this.state.width < 500 ? 0 : undefined,
                      padding: this.state.width < 500 ? "7%" : undefined,
                    }}
                    className={classes.button}
                    disabled={this.state.submitting}
                    type="submit"
                  >
                    {this.state.submitting ? (
                      <CircularProgress className={classes.progress} />
                    ) : (
                      (this.state.form.custom_labels &&
                        this.state.form.custom_labels.checkout_button) ||
                      "Complete Checkout"
                    )}
                  </Button>
                </Grid>
              </form>
            </Paper>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              open={this.state.snackOpen}
              autoHideDuration={2000}
              onClose={this.handleSnackClose}
            >
              <MySnackbarContentWrapper
                variant={this.state.snackVariant}
                message={this.state.snackMessage}
                onClose={this.handleSnackClose}
              />
            </Snackbar>
          </div>
        ) : (
          <Grid container justify={"center"}>
            <div className={classes.loader}>
              <CircularProgress size={100} className={classes.progress} />
            </div>
          </Grid>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(injectStripe(CheckoutForm));
