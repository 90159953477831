import React, { Component } from 'react';
import logo from '../../logo.svg';
import axios from 'axios';
import '../../App.css';
import {
  StripeProvider,
  Elements
} from 'react-stripe-elements';
import InjectedCheckoutForm from './NpForm';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = {
  parent: {
  //  "width": "100vw",
    "height": "100vh",
    "display": "flex",
    "zIndex": -1,
  }
}

class NpTestPage extends Component {
  constructor(props){
  super(props);
  this.state ={
    logo:"https://s3-us-west-2.amazonaws.com/instapayments/null_advancearly",
    form: {},
    line_items: []
  }

}

componentDidMount(){
    this.fetchForm();
}

fetchForm = () => {
    var self = this;
    axios.get(`${apiBaseUrl}/v1/form/npforms/${self.props.match.params.formId}`)
    .then(function (response) {
      if (!response.data.form) {
        window.location.href = "/notfound";
      } else if (!response.data.has_subscription) {
        window.location.href = "/canceled";
      }else{
  
        if (response.data.form && !response.data.form.currency) {
          response.data.form.currency = "usd"
        }
  
        self.setState({
          form: response.data.form,
          custom_fields: response.data.form.custom_fields,
          ...response.data.branding,
          org_id: response.data.org_id,
          stripe_account: response.data.stripe_account,
          has_subscription: response.data.has_subscription,
          firstLoadAttempted: true
        });
      }
  
    })
    .catch((error) => {
      self.setState({
        submitting: false,
        snackOpen: true,
        snackVariant:"error",
        snackMessage:"Error loading form."
      })
      console.log(error);
    });
  }


  render() {

    return (


      <div style={styles.parent}>
      <div className="stripes">
        <div className="stripe s1"></div>
        <div className="stripe s2"></div>
        <div className="stripe s3"></div>
      </div>
        {/*process.env.REACT_APP_PK_TEST_KEY*/}
        {this.state.stripe_account ? (
          <StripeProvider
            apiKey={process.env.REACT_APP_PK_TEST_KEY}
            stripeAccount={this.state.stripe_account}
          >
            <Elements>
              <InjectedCheckoutForm testMode={true} passedData={this.state} {...this.props} />
            </Elements>
          </StripeProvider>
        ) : null}
      </div>

    );
  }
}

export default NpTestPage;
