import React, { Component } from 'react';
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import green from '@material-ui/core/colors/green';
import CheckIcon from '@material-ui/icons/Check';
import Fab from '@material-ui/core/Fab';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper:{
    marginTop: "5%",
    marginBottom: "5%",
    minWidth: "80%",
    height: 600,
    zIndex: '1000'
  },
  button:{
    minWidth: "60%",
    marginTop: "5%"
  },
  textField:{
    marginTop: "1%"
 },
   textFieldHalf:{
      width: '30%',
      float:"left"
  },
  textFieldQuarter:{
     width: '25%',
     float:"left"
 },
  textFieldInLine:{
    marginTop: "1%"
  },
  avatar:{
    marginTop: "3%",
    minHeight: "100px",
    minWidth: "100px"
  },
  fab:{
    marginTop: "25%",
    height: 100,
    width: 100,
    backgroundColor: green[600],
    '&:hover': {
    backgroundColor: green[600],
    },
    //position: "absolute"


  }
});


class Success extends React.Component {
  constructor(props){
  super(props);
  this.state ={

  }

}





handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

  render() {
    const { classes } = this.props;

    var message = "The form has been submitted successfully!"

    if (this.props.match.params.type === "updated") {
      message = "Your payment profile has been successfully updated!"
    }

    return (
      <div className={"child"}>

      <Paper className={classes.paper}>
      <Grid container justify="center">
      <Fab color="green"
      className={classes.fab}
      >
       <CheckIcon />
      </Fab>
      </Grid>
      <Grid container justify="center">
      <Typography variant="h6">
      {message}
      </Typography>
      </Grid>
      </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(Success);
