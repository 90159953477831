import React, { Component } from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { injectStripe } from "react-stripe-elements";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from "@material-ui/core/styles";
import axios from "axios";
import * as uuid from "uuid/v4";
import getSymbolFromCurrency from "currency-symbol-map";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import CardSection from "../formSections/CardSection";
import AddressSection from "../formSections/AddressSection";
import CustomerForm from "./steps/customer";
import ShippingForm from "./steps/shipping";
import PaymentForm from "./steps/payment";
import DiscountForm from "./steps/discount";
import BillingSummary from "./summary";
import CircularProgress from "@material-ui/core/CircularProgress";
import NameSection from "../formSections/NameSection";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root: {
    height: "100vh"
  },
});

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: `https://s3-us-west-2.amazonaws.com/instapayments/${
        this.props.match.params.orgId
      }_${this.props.match.params.org}`,
      form: {},
      line_items: [],
      custom_fields: [],
      metadata: {},
      step: 3,
      height: window.innerHeight,
      width: window.innerWidth,
      firstLoadAttempted: false
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  handleCustomerSubmit = (values) => {
    console.log("Values", values)
    this.changePage(1);
  }

 handleShippingSubmit = (values) =>{
  console.log("Values", values);
  this.changePage(1);
 }

  changePage = (direction=1) => {
    //direction can be either +1 or -1 
    this.setState({
      step: this.state.step + direction
    })
  }

  render() {
    const { classes } = this.props;

    const customTheme = createMuiTheme({
      palette: {
        primary: {
          light: "#aaa7b1",
          main: "#61dafb",
          dark: "#aaa7b1",
          contrastText: "#fff"
        }
      }
      /* secondary: {
            light: '#f2f7fe',
            main: '#B0BEC5',
            dark: '#B0BEC5',
            contrastText: '#fff',
          },
        },*/
    });
    const lineItems = [];
    let total = 0;
    const customFields = [];
    const steps = ["Select Product", "Your Info", "Shipping", "Payment"];

    total = this.state.form.amount_in_cents;

    if (this.state.form && this.state.form.custom_fields) {
      for (let custom_field of this.state.custom_fields) {
        customFields.push(
          <TextField
            key={custom_field._id}
            label={custom_field.name}
            fullWidth
            className={classes.textField}
            required={custom_field.required}
            onChange={this.handleCustomFieldChange(custom_field.name)}
          />
        );
      }
    }

    if (
      this.state.form &&
      this.state.form.taxes &&
      this.state.form.taxes.active
    ) {
      lineItems.push(
        <tr className="line-item line-product">
          <td>
            Taxes {Math.round(this.state.form.taxes.percent * 100) / 100}%
          </td>
          <td>
            {getSymbolFromCurrency(this.state.form.currency)}
            {(
              total *
              (Number(this.state.form.taxes.percent) / 100 / 100)
            ).toFixed(2)}
          </td>
        </tr>
      );
      total = total + total * (Number(this.state.form.taxes.percent) / 100); //this must be last so the tax line item calculates the right amount
    }

    if (this.state.coupon_response) {
      let discount_amount = 0;
      if (this.state.coupon_response.amount_off) {
        discount_amount = this.state.coupon_response.amount_off;
        total = total - this.state.coupon_response.amount_off;
      }

      if (this.state.coupon_response.percent_off) {
        discount_amount =
          total * (this.state.coupon_response.percent_off / 100);
        total = total - discount_amount;
      }

      lineItems.push(
        <tr className="line-item line-product">
          <td>Coupon</td>

          <td>
            {`- ${getSymbolFromCurrency(this.state.form.currency)}${(
              Number(discount_amount) / 100
            ).toFixed(2)}`}
            <span className={classes.interval}>{" " + this.state.coupon}</span>
          </td>
        </tr>
      );
    }
    console.log(this.state)
    return (
      <MuiThemeProvider theme={customTheme}>
        <div className={classes.root}>
        <Stepper activeStep={this.state.step} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    //active: classes.icon
                  }
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <Grid container  justify="center" wrap="wrap-reverse" component="main" >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
          >
            { this.state.step == 0 ?
              /*<ProductForm 
              onCustomerFormSubmit={this.handleCustomerSubmit}
             />*/
             ""
             : null
            }
            { this.state.step == 1 ?
              <CustomerForm 
              onCustomerFormSubmit={this.handleCustomerSubmit}
             />
             : null
            }
            { this.state.step == 2 ?
              <ShippingForm 
              onShippingFormSubmit={this.handleShippingSubmit}
              />
              : null
           }
           { this.state.step == 3 ?
            <PaymentForm 
           
           />
           : null
          }
           
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
          >
            <BillingSummary />
          </Grid>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            open={this.state.snackOpen}
            autoHideDuration={2000}
            onClose={this.handleSnackClose}
          >
            <MySnackbarContentWrapper
              variant={this.state.snackVariant}
              message={this.state.snackMessage}
              onClose={this.handleSnackClose}
            />
          </Snackbar>
        </Grid>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(injectStripe(CheckoutForm));
