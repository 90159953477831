import React, { Component } from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { injectStripe } from "react-stripe-elements";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import qs from "query-string";
import getSymbolFromCurrency from "currency-symbol-map";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CardSection from "../formSections/CardSection";
import AddressSection from "../formSections/AddressSection";
import CircularProgress from "@material-ui/core/CircularProgress";
import NameSection from "../formSections/NameSection";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbar from "@material-ui/core/Snackbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import AppBar from "@material-ui/core/AppBar";
import MySnackbarContentWrapper from "../messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    minWidth: "80%",
    zIndex: "1000"
  },
  paperIframe: {
    minWidth: "80%",
    zIndex: "1000",
    boxShadow: "none",
    paddingBottom: "25%"
  },
  button: {
    minWidth: "60%",
    marginTop: "5%"
  },
  textField: {
    marginTop: "1%"
  },
  textFieldHalf: {
    width: "30%",
    float: "left"
  },
  textFieldQuarter: {
    width: "25%",
    float: "left"
  },
  textFieldInLine: {
    marginTop: "1%"
  },
  avatar: {
    marginTop: "3%",
    minHeight: "100px",
    minWidth: "100px"
  }
});

function addhttp(url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
}

class DonationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: `https://s3-us-west-2.amazonaws.com/instapayments/${
        this.props.match.params.orgId
      }_${this.props.match.params.org}`,
      form: {
        currency: "usd"
      },
      test: "test",
      line_items: [],
      custom_fields: [],
      metadata: {},
      value: 0,
      oneTimeAmount: 50,
      monthlyAmount: 15,
      showCustomMonthly: false,
      showCustomOneTime: false,
      oneTimeActive: true,
      monthlyActive: false,
      height: window.innerHeight,
      width: window.innerWidth,
      firstLoadAttempted: false
    };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.setState(this.props.passedData);
    const queryString = qs.parse(this.props.location.search); //query params
    if (queryString.show_only === "recurring") {
      this.setState({
        monthlyActive: true,
        oneTimeActive: false,
        value: 1
      });
      //by default the tab value is set to 0 so no need for onetime condition
    }
  }

  showNotification = (
    message = "no message recieved in notification function",
    type = "error"
  ) => {
    this.setState({
      snackOpen: true,
      snackVariant: type,
      snackMessage: message
    });
  };

  handleChange = name => event => {
    if (name === "monthlyAmount" && event.target.value === "custom") {
      this.setState({
        showCustomMonthly: true
      });
    } else if (name === "oneTimeAmount" && event.target.value === "custom") {
      this.setState({
        showCustomOneTime: true
      });
    } else {
      this.setState({
        [name]: event.target.value
      });
    }
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  handleTabChange = (event, value) => {
    if (value === 0) {
      this.setState({
        value,
        oneTimeActive: true,
        monthlyActive: false
      });
    } else {
      this.setState({
        value,
        monthlyActive: true,
        oneTimeActive: false
      });
    }
  };

  handleCustomFieldChange = name => event => {
    const existingMetaData = { ...this.state.metadata };
    existingMetaData[name] = event.target.value; //remake the metadata object with a new value
    this.setState({
      metadata: existingMetaData
    });
  };

  handlePassChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    var self = this;
    if (this.state.cardCompleted) {
      this.setState({
        submitting: true
      });

      let otherData = {
        email: this.state.email,
        name: this.state.name,
        address: this.state.address,
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        postal_code: this.state.postal_code,
        zip: this.state.postal_code,
        metadata: this.state.metadata,
        recurring: this.state.monthlyActive,//this determines the path
        one_time: this.state.oneTimeActive,//this determines the path
        monthly_amount: Number(this.state.monthlyAmount),
        one_time_amount: Number(this.state.oneTimeAmount)
      };
      if (this.state.coupon) {
        otherData.coupon = this.state.coupon;
      }
      if(this.state.monthlyActive){
        self.completeCheckout();
      }else{
        //one time charge here
        if (this.state.coupon) {
          otherData.coupon = this.state.coupon;
        }
        this.props.stripe
          .createPaymentMethod("card", self.state.cardElement, {
            billing_details: {
              name: this.state.name,
              email: this.state.email,
              address:{
                line1: this.state.address,
                city: this.state.city,
                //country: this.state.country,
                //state: this.state.state,
                postal_code: this.state.postal_code
              }
            }
          })
          .then(({paymentMethod}) => {
            if (paymentMethod) {
              console.log(paymentMethod)
              if (self.state.pi_id && self.state.customer_id) {
                self.updatePaymentIntent(paymentMethod);//need to save the new card
              }else{
                self.handleOneTime(paymentMethod, otherData);
              }
              
            } else {
              this.setState({
                submitting: false,
                snackOpen: true,
                snackVariant: "error",
                snackMessage: "Please check your card"
              });
            }
          });
      }

    } else {
      this.setState({
        submitting: false,
        snackOpen: true,
        snackVariant: "error",
        snackMessage: "Please check your card"
      });
    }
  };

  handleOneTime = (paymentMethod, otherData) =>{
    var self = this;

    axios
      .post(
        `${apiBaseUrl}/v2/form/forms/donate/onetime/${self.props.match.params.formId}${
          self.props.testMode ? "?test=true" : ""
        }`,
        {
          paymentMethod: paymentMethod,
          email: otherData.email,
          idempotency_keys: otherData.idempotency_keys,
          name: otherData.name,
          address: otherData.address,
          city: otherData.city,
          country: otherData.country,
          state: otherData.state,
          postal_code: otherData.postal_code,
          zip: otherData.postal_code,
          metadata: otherData.metadata,
          coupon: otherData.coupon,
          one_time_amount: Number(otherData.one_time_amount)
        }
      )
      .then(function(response) {
        console.log(response.data);
        self.setState({
          customer_id: response.data.customer,
          pi_id: response.data.id,
          client_secret: response.data.client_secret
        })
        self.handlePaymentIntent(response.data.client_secret);
      })
      .catch(function(error) {
        self.setState({
          submitting: false
        });
        let errorResponse =
          "Error processing. Please check your card and other fields.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorResponse =
            error.response &&
            error.response.data &&
            error.response.data.message;
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse
          });
        } else {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse
          });
        }
      });
  }
  
  updatePaymentIntent = (paymentMethod) => {
    var self = this;

    axios
      .put(
        `${apiBaseUrl}/v2/form/forms/donate/onetime/${self.props.match.params.formId}/pi${
          self.props.testMode ? "?test=true" : ""
        }`,
        {
          paymentMethod: paymentMethod,
          pi_id: this.state.pi_id
        }
      )
      .then(function(response) {
        console.log(response.data);
        self.setState({
          customer_id: response.data.customer,
          pi_id: response.data.id,
          client_secret: response.data.client_secret
        })
        self.handlePaymentIntent(response.data.client_secret);
      })
      .catch(function(error) {
        self.setState({
          submitting: false
        });
        let errorResponse =
          "Error processing. Please check your card and other fields.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorResponse =
            error.response &&
            error.response.data &&
            error.response.data.message;
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse
          });
        } else {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse
          });
        }
      });
  };

  handlePaymentIntent = client_secret => {
    var self = this;
    this.props.stripe
      .handleCardPayment(client_secret, this.state.cardElement)
      .then(function(result) {
        if (result.error) {
          self.setState({
            submitting: false
          });
          self.showNotification(result.error.message);
        } else {
          self.showNotification("payment was successful", "success");
          self.handleSubmitSuccess(result);
        }
      });
  };

completeCheckout = ()=> {
  var self =this;
  var lineItems = [];
  for (const item of self.state.form.line_items) {
    lineItems.push({
      plan:
        self.props.testMode && item.selected_plan.test_id
          ? item.selected_plan.test_id
          : item.selected_plan.id,
      quantity: self.state.monthlyAmount
    });
  }
  console.log("lineItems",lineItems)
  self.setState({
    passed_line_items: lineItems 
  });

    let otherData = {
      email: self.state.email,
      name: self.state.name,
      address: self.state.address,
      city: self.state.city,
      country: self.state.country,
      state: self.state.state,
      postal_code: self.state.postal_code,
      zip: self.state.postal_code,
      metadata: self.state.metadata,
      line_items:lineItems
    };
    if (self.state.coupon) {
      otherData.coupon = self.state.coupon;
    }

    self.props.stripe
      .createToken({
        name: self.state.name,
        address_line1: self.state.address,
        address_city: self.state.city,
        address_country: self.state.country,
        address_state: self.state.state,
        address_zip: self.state.postal_code
      })
      .then(({ token }) => {
        if (token) {
          if (self.state.customerId && self.state.invoiceId) {
            self.handleIncomplete(token, otherData);
          } else {
            self.handleRecurring(token, otherData);
          }
          
        } else {
          self.setState({
            submitting: false,
            snackOpen: true,
            snackVariant: "error",
            snackMessage: "Please check your card"
          });
        }
      });
  }

  handleRecurring = (token, otherData) => {
    var self = this;

    axios
      .post(
        `${apiBaseUrl}/v2/form/forms/${self.props.match.params.formId}${
          self.props.testMode ? "?test=true" : ""
        }`,
        {
          token: token.id,
          full_token: token,
          email: otherData.email,
          name: otherData.name,
          address: otherData.address,
          city: otherData.city,
          country: otherData.country,
          state: otherData.state,
          postal_code: otherData.postal_code,
          zip: otherData.postal_code,
          metadata: otherData.metadata,
          coupon: otherData.coupon,
          line_items: self.state.passed_line_items //for v2 forms we can simply change the name passed to the backend
        }
      )
      .then(function(response) {
        console.log(response.data)
        self.setState({
          submitting: false,
          customerId: response.data.customer_id,
          invoiceId: response.data.invoice_id
        });
        self.handleSubmitSuccess(response);
      })
      .catch(function(error) {
        self.handleCardDeclineResponse(error);
      });
  };

   /*Handles a card decline with new payment intent API */
   handleIncomplete = (token, otherData) => {
    var self = this;
    axios
      .post(`${apiBaseUrl}/v2/form/forms/transaction/incomplete`, {
        token: token.id,
        org: self.state.org_id,
        customerId: self.state.customerId,
        invoiceId: self.state.invoiceId,
        full_token: token,
        email: otherData.email,
        name: otherData.name,
        address: otherData.address,
        city: otherData.city,
        country: otherData.country,
        state: otherData.state,
        postal_code: otherData.postal_code,
        zip: otherData.postal_code,
        metadata: otherData.metadata,
        coupon: otherData.coupon
      })
      .then(function(response) {
        self.handleSubmitSuccess(response);
      })
      .catch(function(error) {
        self.handleCardDeclineResponse(error);
      });
  };

  handleSCA = requestBody => {
    var self = this;
    self.showNotification("Additional verification is required.", "warning");
    self.props.stripe
      .handleCardPayment(requestBody.pi_secret, self.state.cardElement, {
        payment_method_data: {
          billing_details: {
            name: self.state.name
          }
        }
      })
      .then(function(result) {
        console.log(result);
        if (
          result.paymentIntent &&
          result.paymentIntent.status === "succeeded"
        ) {
          console.log("the payment succeeded");
          self.showNotification("The payment was successful.", "success");
          let url = "/success";
          let queryParams = `?customer_id=${
            requestBody.customer_id
          }&subscription_id=${requestBody.subscription_id}&plan_id=${
            requestBody.plan
          }&product_id=${requestBody.product}`;

          if (self.state.form.redirect_url) {
            url = addhttp(self.state.form.redirect_url);
          }

          window.top.location.href = url + queryParams;
        } else if (result.error) {
          console.log("ERROR AFTER SCA", result);
          self.setState({
            submitting: false
          });
          if (result.error && result.error.message) {
            self.showNotification(result.error.message);
          } else {
            self.showNotification(
              "There was an error after additional verification."
            );
          }
        } else {
          self.setState({
            submitting: false
          });
          self.showNotification(
            "Error handling additional verification response."
          );
        }
        // Handle result.error or result.paymentIntent
      })
      .catch(err => {
        self.setState({
          submitting: false
        });
        self.showNotification("Error triggering response.");
        console.log(err);
      });
  };

    /*Deals with the various ways an error can be returned*/
    handleCardDeclineResponse = error => {
      var self = this;
      console.log("ERROR STATUS", error.response);
  
      let errorResponse =
        "Error processing. Please check your card and other fields.";
      if (error.response && error.response.data && error.response.data.message) {
        errorResponse =
          error.response && error.response.data && error.response.data.message;
  
        const requestBody = error.response.data;
        if (
          error.response.status === 406 &&
          requestBody.pi_status === "requires_action"
        ) {
          self.handleSCA(requestBody); //handle the additional verification if our backend returns 406
        } else if (
          error.response.status === 406 &&
          requestBody.pi_status === "requires_payment_method"
        ) {
          /*save the customer ID and invoice ID to state and ask for a new card*/
          self.setState({
            submitting: false,
            customerId: error.response.data.customer_id,
            invoiceId: error.response.data.invoice_id
          });
          self.showNotification(errorResponse);
        } else {
          self.setState({
            submitting: false
          });
          self.showNotification(errorResponse);
        }
        console.log(error);
      } else {
        /*This is a more generic card failure*/
        self.setState({
          submitting: false
        });
        self.showNotification(errorResponse);
      }
    };


  handleCardReady = element => {
    this.setState({ cardElement: element });
  };

  handleCardChange = event => {
    if (event.error) {
      this.setState({
        snackOpen: true,
        snackVariant: "error",
        snackMessage: event.error.message
      });
    } else if (event.error === undefined) {
      this.setState({
        snackOpen: false
      });
    }

    if (event.complete) {
      this.setState({
        cardCompleted: true
      });
    }
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };
  
  handleSubmitSuccess = (response) => {
    var self = this;
    self.setState({
      submitting: false
    });
    let url = "/success";
    let queryParams = `?customer_id=${
      this.state.customer_id
    }`;
    if (self.state.form.redirect_url) {
      function addhttp(url) {
        if (!/^(f|ht)tps?:\/\//i.test(url)) {
          url = "http://" + url;
        }
        return url;
      }
      url = addhttp(self.state.form.redirect_url);
    }
    window.top.location.href = url + queryParams;
  };

  render() {
    const { classes, theme } = this.props;
    const lineItems = [];
    let total = 0;
    const customFields = [];
    const queryString = qs.parse(this.props.location.search); //query params
    console.log(queryString);
    for (let item of this.state.line_items) {
      total = total + item.selected_plan.amount;

      lineItems.push(
        <tr className="line-item line-product">
          <td>{item.name}</td>

          <td>{`${getSymbolFromCurrency(this.state.form.currency)}${(
            item.selected_plan.amount / 100
          ).toFixed(2)}`}</td>
        </tr>
      );
    }
    console.log(this.state);
    if (
      this.state.form &&
      this.state.form.setup_fee &&
      this.state.form.setup_fee.active
    ) {
      total = total + Number(this.state.form.setup_fee.amount_in_cents);
      lineItems.push(
        <tr className="line-item line-product">
          <td>Initial Fee</td>
          <td>{`${getSymbolFromCurrency(this.state.form.currency)}${(
            Number(this.state.form.setup_fee.amount_in_cents) / 100
          ).toFixed(2)}`}</td>
        </tr>
      );
    }
    if (this.state.form && this.state.form.custom_fields) {
      for (let custom_field of this.state.custom_fields) {
        customFields.push(
          <TextField
            key={custom_field._id}
            label={custom_field.name}
            fullWidth
            className={classes.textField}
            required={custom_field.required}
            onChange={this.handleCustomFieldChange(custom_field.name)}
          />
        );
      }
    }

    if (
      this.state.form &&
      this.state.form.taxes &&
      this.state.form.taxes.active
    ) {
      lineItems.push(
        <tr className="line-item line-product">
          <td>
            Taxes {Math.round(this.state.form.taxes.percent * 100) / 100}%
          </td>
          <td>
            {getSymbolFromCurrency(this.state.form.currency)}
            {(
              total *
              (Number(this.state.form.taxes.percent) / 100 / 100)
            ).toFixed(2)}
          </td>
        </tr>
      );

      total = total + total * (Number(this.state.form.taxes.percent) / 100); //this must be last so the tax line item calculates the right amount
    }
    let currency = getSymbolFromCurrency("usd");
    if (this.state.form) {
      currency = getSymbolFromCurrency(this.state.form.currency);
    }
    const donationMonthlyAmounts = [];
    const donationOneTimeAmounts = []
    let monthlyDonationAmounts = [15, 30, 50, 100, 250];
    let onetimeDonationAmounts = [50, 100, 250, 500, 1000];
    if (this.state.donation_defaults) {
      monthlyDonationAmounts = this.state.donation_defaults.recurring;
      onetimeDonationAmounts = this.state.donation_defaults.onetime;
    }

    for (const amount of monthlyDonationAmounts) {
      donationMonthlyAmounts.push(
        <MenuItem key={amount} value={amount}>
          {currency}
          {amount}
        </MenuItem>
      );
    }
    for (const amount of onetimeDonationAmounts) {
      donationOneTimeAmounts.push(
        <MenuItem key={amount} value={amount}>
          {currency}
          {amount}
        </MenuItem>
      );
    }
    donationMonthlyAmounts.push(
      <MenuItem key={"custom"} value={"custom"}>
        Other Amount
      </MenuItem>
    );

    donationOneTimeAmounts.push(
      <MenuItem key={"custom"} value={"custom"}>
        Other Amount
      </MenuItem>
    );

    return (
      <div className={"child"}>
        {this.state.firstLoadAttempted ? (
          <div>
            <Paper
              className={
                this.state.width < 500 ? classes.paperIframe : classes.paper
              }
            >
              <Grid container justify="center">
                <div style={{ maxHeight: "3%", marginTop: "1%" }}>
                {this.state.form &&
                  this.state.form.custom_logo &&
                  this.state.form.custom_logo.active ? (
                    <img
                      src={this.state.form.custom_logo.custom_logo_url}
                      style={{ maxHeight: "120px" }}
                      alt="logo"
                    />
                  ) : this.state.logo ? (
                    <img
                      src={this.state.logo}
                      style={{ maxHeight: "120px" }}
                      alt="logo"
                    />
                  ) : null}
                </div>
              </Grid>
              <Grid container justify="center">
                <div id="line-items">
                  {!queryString.show_only ? (
                    <AppBar position="static" color="default">
                      <Tabs
                        value={this.state.value}
                        onChange={this.handleTabChange}
                        variant="fullWidth"
                        TabIndicatorProps={{
                          style: {
                            backgroundColor:
                              this.state.primary_color || undefined //changes the underline for active tabs
                          }
                        }}
                        style={
                          { color: this.state.primary_color || undefined } //changes the tab text color
                        }
                        centered
                      >
                        <Tab label={this.state.form.custom_labels && this.state.form.custom_labels.donation_tab_1 || "One Time Donation"} />
                        <Tab label={this.state.form.custom_labels && this.state.form.custom_labels.donation_tab_2 || "Monthly Donation"} />
                      </Tabs>
                    </AppBar>
                  ) : null}
                  <SwipeableViews
                    index={this.state.value}
                    onChangeIndex={this.handleChangeIndex}
                  >
                    <div className="tabContent">
                      <Grid container justify="center">
                        {!this.state.showCustomOneTime ? (
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Select"
                            className={classes.textField}
                            value={this.state.oneTimeAmount}
                            onChange={this.handleChange("oneTimeAmount")}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                            helperText={this.state.form.custom_labels && this.state.form.custom_labels.donation_helper_text_1 || "Please select your one time donation amount"}
                            margin="normal"
                            variant="outlined"
                          >
                            {donationOneTimeAmounts}
                          </TextField>
                        ) : (
                          <TextField
                            label="Custom Amount"
                            required
                            value={this.state.oneTimeAmount}
                            helperText={this.state.form.custom_labels && this.state.form.custom_labels.donation_helper_text_1 || "Amount you want to donate."}
                            type="number"
                            InputProps={{
                              inputProps: {
                                step: "any",
                                min: 10
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {currency}
                                </InputAdornment>
                              )
                            }}
                            className={classes.textField}
                            onChange={this.handleChange("oneTimeAmount")}
                          />
                        )}
                      </Grid>
                    </div>

                    <div className="tabContent">
                      <Grid container justify="center">
                        {!this.state.showCustomMonthly ? (
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Select"
                            className={classes.textField}
                            value={this.state.monthlyAmount}
                            onChange={this.handleChange("monthlyAmount")}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu
                              }
                            }}
                            helperText={this.state.form.custom_labels && this.state.form.custom_labels.donation_helper_text_2 || "Please select your monthly donation amount."}
                            margin="normal"
                            variant="outlined"
                          >
                            {donationMonthlyAmounts}
                          </TextField>
                        ) : (
                          <TextField
                            label="Custom Amount"
                            required
                            value={this.state.monthlyAmount}
                            helperText={this.state.form.custom_labels && this.state.form.custom_labels.donation_helper_text_2 || "Amount you want to donate monthly."}
                            type="number"
                            InputProps={{
                              inputProps: {
                                step: "any",
                                min: 10
                              },
                              startAdornment: (
                                <InputAdornment position="start">
                                  {currency}
                                </InputAdornment>
                              )
                            }}
                            className={classes.textField}
                            onChange={this.handleChange("monthlyAmount")}
                          />
                        )}
                      </Grid>
                    </div>
                  </SwipeableViews>
                </div>
              </Grid>
              <form className={"form"} onSubmit={this.handleSubmit}>
                <Grid container spacing={8} >
                  <NameSection
                    {...this.props}
                    custom_labels={this.state.form.custom_labels}
                    changeInput={this.handlePassChange}
                  />
                  {this.state.form.hide_address ? null : (
                    <AddressSection
                      {...this.props}
                      custom_labels={this.state.form.custom_labels}
                      changeInput={this.handlePassChange}
                    />
                  )}
                  {customFields}
                  <Grid item xs={12}>
                  <CardSection
                      {...this.props}
                      onCardReady={this.handleCardReady}
                      onCardChange={this.handleCardChange}
                    />
                  </Grid>
                  <Grid container justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={this.state.width < 500 ? true : false}
                    style={{
                      backgroundColor: this.state.primary_color || "#814ff8",
                      position: this.state.width < 500 ? "fixed" : undefined,
                      bottom: this.state.width < 500 ? 0 : undefined,
                      padding: this.state.width < 500 ? "7%" : undefined
                    }}
                    className={classes.button}
                    disabled={this.state.submitting}
                    type="submit"
                  >
                    {this.state.submitting ? (
                      <CircularProgress className={classes.progress} />
                    ) : (
                      this.state.form.custom_labels && this.state.form.custom_labels.checkout_button || "Complete Checkout"
                    )}
                  </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={this.state.snackOpen}
              autoHideDuration={2000}
              onClose={this.handleSnackClose}
            >
              <MySnackbarContentWrapper
                variant={this.state.snackVariant}
                message={this.state.snackMessage}
                onClose={this.handleSnackClose}
              />
            </Snackbar>
          </div>
        ) : (
          <Grid container justify={"center"}>
            <div className={classes.loader}>
              <CircularProgress size={100} className={classes.progress} />
            </div>
          </Grid>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(injectStripe(DonationForm));
