import React from "react";
import {
  BrowserRouter, // this is often used as "BrowserRouter as Router"
  Route,
  Switch,
} from "react-router-dom";
import { Redirect } from "react-router";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Form from "./views/signup/FormPage.js";
import FormTest from "./views/signup/FormTestPage.js";
import MultistepFormPage from "./views/multistep/MultistepFormPage.js";
import DonationPage from "./views/donation/DonationPage.js";
import DonationTestPage from "./views/donation/DonationTestPage.js";
import MultiProductPage from "./views/multiproduct/MultiProductPage.js";
import MultiProductTestPage from "./views/multiproduct/MultiProductTestPage.js";
import QuantityPage from "./views/quantity/QuantityPage.js";
import QuantityTest from "./views/quantity/QuantityTestPage.js";
import OneTimePage from "./views/onetime/OneTimePage.js";
import NpPage from "./views/npform/NpPage.js";
import NpTestPage from "./views/npform/NpTestPage.js";
import UpdatePage from "./views/update/UpdatePage.js";
import UpdateTestPage from "./views/update/UpdateTestPage.js";
import FindForm from "./views/update/FindForm.js";
import TestForm from "./views/TestFormPage.js";
import Success from "./views/SuccessPage2.js";
import Canceled from "./views/Canceled.js";
import NotFound from "./views/NotFound.js";
import StripeCheckout from "./views/stripeCheckout/StripeCheckout.js";
import CheckoutUpdatePage from "./views/CheckoutUpdatePage.js";
import blueGrey from "@material-ui/core/colors/blueGrey";

const theme = createMuiTheme({
  palette: {
    primary: blueGrey,
  },
  /*palette: {
    primary: {
      light: '#aaa7b1',
      main: '#aaa7b1',
      dark: '#aaa7b1',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f2f7fe',
      main: '#B0BEC5',
      dark: '#B0BEC5',
      contrastText: '#fff',
    },
  },*/
  /*
   // Name of the component ⚛️ / style sheet
   MuiButton: {
     // Name of the rule
     root: {
       // Some CSS
       background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
       borderRadius: 3,
       border: 0,
       color: 'white',

       boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
     },
   },
 },*/
});

const PrimaryLayout = () => (
  <div className="primary-layout">
    <main>
      <Switch>
        <Route path="/signup/:org/:orgId/:formId" exact component={Form} />
        <Route path="/signup/:formId/test" exact component={FormTest} />
        <Route path="/signup/:formId" exact component={Form} />

        <Route
          path="/signup-quantity/:org/:orgId/:formId"
          exact
          component={QuantityPage}
        />
        <Route
          path="/signup-quantity/:formId/test"
          exact
          component={QuantityTest}
        />
        <Route path="/signup-quantity/:formId" exact component={QuantityPage} />

        <Route
          path="/donate/:org/:orgId/:formId"
          exact
          component={DonationPage}
        />
        <Route path="/donate/:formId/test" exact component={DonationTestPage} />
        <Route path="/donate/:formId" exact component={DonationPage} />

        <Route path="/multiprod/:formId" exact component={MultiProductPage} />
        <Route
          path="/multiprod/:formId/test"
          exact
          component={MultiProductTestPage}
        />

        <Route
          path="/onetime/:org/:orgId/:formId"
          exact
          component={OneTimePage}
        />
        <Route path="/onetime/:formId" exact component={OneTimePage} />

        <Route path="/np/:formId" exact component={NpPage} />
        <Route path="/np/:formId/test" exact component={NpTestPage} />

        <Route path="/multistep/:formId" exact component={MultistepFormPage} />

        <Route path="/checkout/:id" exact component={StripeCheckout} />

        <Route
          path="/update/:orgId/:passedHash"
          exact
          component={CheckoutUpdatePage}
        />

        <Route
          path="/update/:org/:orgId/:customerId/:passedHash"
          exact
          component={UpdatePage}
        />
        <Route
          path="/update/:org/:orgId/:customerId/:passedHash/test"
          exact
          component={UpdateTestPage}
        />
        <Route path="/find/:org/:orgId" exact component={FindForm} />

        <Route path="/success" exact component={Success} />
        <Route path="/success/:type" exact component={Success} />
        <Route path="/canceled" exact component={Canceled} />
        <Route exact component={NotFound} />
      </Switch>
    </main>
  </div>
);

const App = () => (
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <PrimaryLayout />
    </MuiThemeProvider>
  </BrowserRouter>
);

export default App;
