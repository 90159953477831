import React, { Component } from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { injectStripe } from "react-stripe-elements";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Avatar from "@material-ui/core/Avatar";
import CardSection from "../formSections/CardSection";
import AddressSection from "../formSections/AddressSection";
import CircularProgress from "@material-ui/core/CircularProgress";
import NameSection from "../formSections/NameSection";
import Snackbar from "@material-ui/core/Snackbar";
import MySnackbarContentWrapper from "../messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: "70%",
    minWidth: "80%",
    zIndex: "1000",
  },
  button: {
    minWidth: "60%",
    marginTop: "5%",
  },
  textField: {
    marginTop: "1%",
  },
  textFieldHalf: {
    width: "30%",
    float: "left",
  },
  textFieldQuarter: {
    width: "25%",
    float: "left",
  },
  textFieldInLine: {
    marginTop: "1%",
  },
  interval: {
    fontSize: "12px",
  },
});

class UpdateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: `https://s3-us-west-2.amazonaws.com/instapayments/${
        this.props.match.params.orgId
      }_${this.props.match.params.org}`,
    };
  }

  componentDidMount() {
    this.setState(this.props.passedData);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handlePassChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    var self = this;
    if (this.state.cardCompleted) {
      this.setState({
        submitting: true,
      });
      this.props.stripe
        .createPaymentMethod("card", self.state.cardElement, {
          billing_details: {
            name: this.state.name,
            email: this.state.email,
            address: {
              line1: this.state.address,
              city: this.state.city,
              //country: this.state.country,
              //state: this.state.state,
              postal_code: this.state.postal_code,
            },
          },
        })
        .then(({ paymentMethod }) => {
          if (paymentMethod) {
            console.log("Payment Method", paymentMethod);
            if (self.state.setupIntent) {
              self.handleSetupIntent(paymentMethod); //need to save the new card
            } else {
              self.createIntent(paymentMethod);
            }
          } else {
            this.setState({
              submitting: false,
              snackOpen: true,
              snackVariant: "error",
              snackMessage: "Please check your card",
            });
          }
        });
    } else {
      this.setState({
        submitting: false,
        snackOpen: true,
        snackVariant: "error",
        snackMessage: "Please check your card",
      });
    }
  };

  createIntent = (paymentMethod) => {
    var self = this;

    axios
      .post(
        `${apiBaseUrl}/v2/form/forms/update/${self.props.match.params.orgId}/${
          self.props.match.params.passedHash
        }${self.props.testMode ? "?test=true" : ""}`,
        {
          paymentMethod: paymentMethod,
        }
      )
      .then(function(response) {
        console.log(response.data);
        self.handleSetupIntent(response.data);
      })
      .catch(function(error) {
        self.setState({
          submitting: false,
        });
        let errorResponse =
          "Error processing. Please check your card and other fields.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorResponse =
            error.response &&
            error.response.data &&
            error.response.data.message;
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse,
          });
        } else {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse,
          });
        }
      });
  };

  handleSetupIntent = (passedSetupIntent) => {
    var self = this;
    self.props.stripe
      .handleCardSetup(
        passedSetupIntent.client_secret,
        self.state.cardElement,
        {
          payment_method_data: {
            billing_details: {
              name: self.state.name,
            },
          },
        }
      )
      .then(({ setupIntent }) => {
        console.log("SUCCESS", setupIntent, passedSetupIntent);
        self.attachToCustomer(setupIntent.payment_method);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  attachToCustomer = (paymentMethodId) => {
    var self = this;

    axios
      .post(
        `${apiBaseUrl}/v2/form/forms/update/associate/${
          self.props.match.params.orgId
        }/${self.props.match.params.passedHash}${
          self.props.testMode ? "?test=true" : ""
        }`,
        {
          payment_method: paymentMethodId,
        }
      )
      .then(function(response) {
        console.log(response.data);
        self.handleSuccess();
      })
      .catch(function(error) {
        self.setState({
          submitting: false,
        });
        let errorResponse =
          "Error processing. Please check your card and other fields.";
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorResponse =
            error.response &&
            error.response.data &&
            error.response.data.message;
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse,
          });
        } else {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: errorResponse,
          });
        }
      });
  };

  handleSuccess = () => {
    var self = this;
    self.setState({
      submitting: false,
      snackOpen: true,
      snackVariant: "success",
      snackMessage: "Your payment profile was updated successfully.",
    });

    window.location.href = "/success/updated";
  };

  handleCardChange = (event) => {
    console.log(event);
    if (event.error) {
      this.setState({
        snackOpen: true,
        snackVariant: "error",
        snackMessage: event.error.message,
      });
    } else if (event.error === undefined) {
      this.setState({
        snackOpen: false,
      });
    }

    if (event.complete) {
      this.setState({
        cardCompleted: true,
      });
    }
  };
  handleCardReady = (element) => {
    this.setState({ cardElement: element });
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  render() {
    const { classes } = this.props;
    console.log(this.state);

    return (
      <div className={"child"}>
        <Paper className={classes.paper}>
          <Grid container justify="center">
            <div style={{ maxHeight: "3%", marginTop: "1%" }}>
              {this.state.logo ? (
                <img
                  src={this.state.logo}
                  style={{ maxHeight: "120px" }}
                  alt="logo"
                />
              ) : null}
              <Typography>
                Please update your card
                {this.state.business_name
                  ? " with" + this.state.business_name
                  : ""}
                .
              </Typography>
            </div>
          </Grid>
          <form className={"form"} onSubmit={this.handleSubmit}>
            <Grid container spacing={0} justify="center">
              <TextField
                label="Name"
                fullWidth
                required
                className={classes.textField}
                onChange={this.handleChange("name")}
              />
              <Grid item xs={12}>
                <AddressSection
                  {...this.props}
                  changeInput={this.handlePassChange}
                />
                <CardSection
                  {...this.props}
                  onCardChange={this.handleCardChange}
                  onCardReady={this.handleCardReady}
                />
              </Grid>
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: this.state.primary_color || "#814ff8",
                }}
                className={classes.button}
                disabled={this.state.submitting}
                type="submit"
              >
                {this.state.submitting ? (
                  <CircularProgress className={classes.progress} />
                ) : (
                  "Update Payment Profile"
                )}
              </Button>
            </Grid>
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={2000}
          onClose={this.handleSnackClose}
        >
          <MySnackbarContentWrapper
            variant={this.state.snackVariant}
            message={this.state.snackMessage}
            onClose={this.handleSnackClose}
          />
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(injectStripe(UpdateForm));
