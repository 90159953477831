import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
const styles = theme => ({
  root: {
    height: "100vh"
  },
  paper:{
      marginTop: "5%",
      marginLeft: "2%",
      padding: "5%"
  },
  halfWidthText:{
    display: "inline"
  },
  submit:{
    width: "35%",
    marginTop:"3%"
  }
});



function DiscountForm(props) {
  const { classes } = props;
  return (
    <div>
        <Paper className={classes.paper}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="discount"
              label="Discount"
              name="discount"
              autoFocus
            />   
            </Paper>
    </div>
  );
}

DiscountForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DiscountForm);
