import React from "react";
import { CardElement } from "react-stripe-elements";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";
import queryString from "query-string";

class AddressSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      state: "",
      country: "",
      postal_code: ""
    };
  }
  componentDidMount() {
    this.prePopulate();
  }
  prePopulate = async () => {
    const { address, city, state, country, postal_code } = queryString.parse(
      this.props.location.search
    );

    await this.setState({
    address,
    city,
    state,
    country,
    postal_code
    });

    this.props.changeInput("address", address);
    this.props.changeInput("city", city);
    this.props.changeInput("state", state);
    this.props.changeInput("postal_code", postal_code);
    this.props.changeInput("country", country);
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  passChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
    this.props.changeInput(name, event.target.value);
  };

  render() {
    const { classes, custom_labels } = this.props;
    return (
      <Grid container spacing={8} justify="center">
        <Grid item xs={12} sm={12}>
          <TextField
            label={(custom_labels && custom_labels.address) || "Address"}
            fullWidth
            value={this.state.address}
            required
            onChange={this.passChange("address")}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label={(custom_labels && custom_labels.city) || "City"}
            fullWidth
            value={this.state.city}
            required
            onChange={this.passChange("city")}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label={(custom_labels && custom_labels.state) || "State"}
            fullWidth
            value={this.state.state}
            required
            onChange={this.passChange("state")}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label={
              (custom_labels && custom_labels.postal_code) || "Postal Code"
            }
            fullWidth
            value={this.state.postal_code}
            required
            onChange={this.passChange("postal_code")}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label={(custom_labels && custom_labels.country) || "Country"}
            fullWidth
            value={this.state.country}
            required
            onChange={this.passChange("country")}
          />
        </Grid>
      </Grid>
    );
  }
}

export default AddressSection;
