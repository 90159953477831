import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { CountryRegionData } from "react-country-region-selector";

import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";

const styles = theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    marginTop: "5%",
    padding: "5%"
  },
  halfWidthText: {
    display: "inline"
  },
  submit: {
    width: "35%",
    marginTop: "3%"
  }
});

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      phone: "",
      email: "",
      country: "",
      region: "",
      form: {
        countryWhitelist: ["US", "CA", "GB"]
      }
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  handleFormSubmit = event => {
    event.preventDefault();
    const formValues = {
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      region: this.state.region,
      country: this.state.country[1]
    };
    this.props.onShippingFormSubmit(formValues);
  };

  render() {
    const { classes } = this.props;
    const props = this.props;

    return (
      <div>
        <Paper className={classes.paper}>
          <Typography variant="h5">Payment</Typography>
          <form className={classes.form} onSubmit={this.handleFormSubmit}>
            <Grid container spacing={24}>
            <Grid item xs={12} md={12}>
                <TextField
                  margin="normal"
                  required
                  onChange={this.handleChange("card_name")}
                  fullWidth
                  id="card_name"
                  label="Name on Card"
                  name="name"
                  autoComplete="card-name"
                  autoFocus
                />
              </Grid>
            <Grid item xs={12}>
                <CardElement />
              </Grid>
              {/*<Grid item xs={12}>
                <CardNumberElement />
              </Grid>
              <Grid item xs>
                <CardExpiryElement />
              </Grid>
              <Grid item xs>
                <CardCVCElement />
     </Grid>*/}
            </Grid>
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Finish
              </Button>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}
export default withStyles(styles)(PaymentForm);
