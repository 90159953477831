import React, { Component } from 'react';
import logo from '../logo.svg';
import '../App.css';
import {
  injectStripe,
} from 'react-stripe-elements';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import getSymbolFromCurrency from 'currency-symbol-map';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import CardSection from './formSections/CardSection'
import AddressSection from './formSections/AddressSection'
import CircularProgress from '@material-ui/core/CircularProgress';
import NameSection from './formSections/NameSection'
import Snackbar from '@material-ui/core/Snackbar';
import MySnackbarContentWrapper from './messages/customSnack';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3005";
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper:{
    minWidth: "80%",
    zIndex: '1000'
  },
  button:{
    minWidth: "60%",
    marginTop: "5%"
  },
  textField:{
    marginTop: "1%"
 },
   textFieldHalf:{
      width: '30%',
      float:"left"
  },
  textFieldQuarter:{
     width: '25%',
     float:"left"
 },
  textFieldInLine:{
    marginTop: "1%"
  },
  avatar:{
    marginTop: "3%",
    minHeight: "100px",
    minWidth: "100px"
  },
});


class CheckoutForm extends React.Component {
  constructor(props){
  super(props);
  this.state ={
    logo:`https://s3-us-west-2.amazonaws.com/instapayments/${this.props.match.params.orgId}_${this.props.match.params.org}`,
    form: {},
    line_items: []
  }

}

componentDidMount(){
  var self = this;
  axios.get(`${apiBaseUrl}/api/forms/${self.props.match.params.org}/${self.props.match.params.orgId}/${self.props.match.params.formId}`)
  .then(function (response) {
    if (!response.data.form.currency) {
      response.data.form.currency = "usd"
    }
    self.setState({
      form: response.data.form,
      line_items: response.data.form.line_items,
      ...response.data.branding
    });
    console.log(self.state)
  })
  .catch(function (error) {
    console.log(error);
  });
}

handleChange = name => event => {
  this.setState({
    [name]: event.target.value,
  });
};

handlePassChange = (name, value) => {
  this.setState({
    [name]: value,
  });
};

handleSubmit = (ev) => {
  ev.preventDefault();
  var self = this;
  if (this.state.cardCompleted) {
  this.setState({
    submitting: true
  })
  console.log(this.state)
  let otherData = {
    email: this.state.email,
    name: this.state.name,
    address: this.state.address,
    city: this.state.city,
    country: this.state.country,
    state: this.state.state,
    postal_code: this.state.postal_code,
    zip: this.state.postal_code
  }
  if (this.state.coupon) {
    otherData.coupon = this.state.coupon
  }
  this.props.stripe.createToken({
    name: this.state.name,
    address_line1: this.state.address,
    address_city: this.state.city,
    address_country: this.state.country,
    address_state: this.state.state,
    address_zip: this.state.postal_code
  }).then(({token}) => {
    if (token) {
      self.postToken(token, otherData)
    }else{
      this.setState({
        submitting: false,
        snackOpen: true,
        snackVariant:"error",
        snackMessage:"Please check your card"
      })
    }
  });
}else{
  this.setState({
    submitting: false,
    snackOpen: true,
    snackVariant:"error",
    snackMessage:"Please check your card"
  })
}
};

postToken = (token, otherData) =>{
  var self = this;
console.log(token, otherData)
axios.post(`${apiBaseUrl}/api/forms/${self.props.match.params.org}/${self.props.match.params.orgId}/${self.props.match.params.formId}`,
  {
    token: token.id,
    full_token: token,
    email: otherData.email,
    name: otherData.name,
    address: otherData.address,
    city: otherData.city,
    country: otherData.country,
    state: otherData.state,
    postal_code: otherData.postal_code,
    zip: otherData.postal_code,
    coupon: otherData.coupon
  })
  .then(function (response) {
    console.log(response);
    self.setState({
      submitting: false
    })
    window.location.href = "/success"
  })
  .catch(function (error) {
    self.setState({
      submitting: false
    })
    if (error.response && error.response.status=== 402) {
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "This card was declined."
      });
    }else if(error.response && error.response.status=== 400 && self.state.form.coupon_enabled){
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "That coupon looks invalid."
      });
    }else{
      self.setState({
        snackOpen: true,
        snackVariant:"error",
        snackMessage: "There was an error processing this charge."
      });
    }
    console.log(error);
  });


}

handleCardChange = (event) =>{
  console.log(event)
if (event.error) {
  this.setState({
    snackOpen: true,
    snackVariant:"error",
    snackMessage:event.error.message
  });
}else if(event.error === undefined){
  this.setState({
    snackOpen: false
  });
}

if(event.complete){
  this.setState({
    cardCompleted: true
  });
}
}

handleSnackClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  this.setState({ snackOpen: false });
};

  render() {
    const { classes } = this.props;
    const lineItems = []
    let total = 0;
  for (let item of this.state.line_items) {
    console.log(total, item.selected_plan.amount)
    total = total + item.selected_plan.amount

    lineItems.push(
      <tr className="line-item line-product">
            <td>{item.name}</td>

            <td>{`${getSymbolFromCurrency(this.state.form.currency)}${(item.selected_plan.amount/100).toFixed(2)}`}</td>
          </tr>
        )
  }
  console.log("total after ")

  if (this.state.form && this.state.form.setup_fee && this.state.form.setup_fee.active) {
    total = total + Number(this.state.form.setup_fee.amount_in_cents);
    lineItems.push(
      <tr className="line-item line-product">
            <td>Initial Fee</td>
            <td>{`${getSymbolFromCurrency(this.state.form.currency)}${((Number(this.state.form.setup_fee.amount_in_cents)/100)).toFixed(2)}`}</td>
          </tr>
        )
  }

  if (this.state.form && this.state.form.coupon_enabled) {

  }

  if (this.state.form && this.state.form.taxes && this.state.form.taxes.active) {
    lineItems.push(
      <tr className="line-item line-product">
            <td>Taxes {Math.round(this.state.form.taxes.percent* 100) / 100}%</td>
            <td>{getSymbolFromCurrency(this.state.form.currency)}{(total * (Number(this.state.form.taxes.percent)/100/100)).toFixed(2)}</td>
          </tr>
        )

    total = total + (total * (Number(this.state.form.taxes.percent)/100)) //this must be last so the tax line item calculates the right amount
  }

    return (
      <div className={"child"}>

      <Paper className={classes.paper}>
      <Grid container justify="center">
      <div style={{maxHeight: "3%", marginTop: "1%"}}>
      {this.state.logo ?
      <img src={this.state.logo} style={{maxHeight: "120px"}} alt="logo"/>
      :
      null
      }
      </div>
      </Grid>
      <Grid container justify="center">
      <div id="line-items">
        <table className="item-table">
        <tbody>
        {lineItems}

          </tbody>
          <tfoot>
          <tr className="line-item total">
            <td><strong>TOTAL</strong></td>

            <td><span id="checkout-total">{getSymbolFromCurrency(this.state.form.currency)}{(total/100).toFixed(2)}</span></td>
          </tr>
          </tfoot>
        </table>
        <Divider/>
      </div>
      </Grid>
      <form className={"form"} onSubmit={this.handleSubmit}>
      <Grid container spacing={0} justify="center">

      <NameSection {...this.props} changeInput={this.handlePassChange}/>
      <AddressSection {...this.props} changeInput={this.handlePassChange}/>
      {this.state.form && this.state.form.coupon_enabled ?
        <TextField
        label="Promo Code"
        fullWidth
        className={classes.textField}
        onChange={this.handleChange('coupon')}
        />
        :
        null
      }
      <Grid item xs={12}>
      <CardSection {...this.props} onCardChange={this.handleCardChange}/>
      </Grid>
        <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={this.state.submitting}
        type="submit"
        >
          { this.state.submitting ? <CircularProgress className={classes.progress} /> : "Complete Checkout"}
        </Button>
        </Grid>
      </form>

      </Paper>
      <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={this.state.snackOpen}
      autoHideDuration={2000}
      onClose={this.handleSnackClose}
      >
      <MySnackbarContentWrapper
      variant={this.state.snackVariant}
      message={this.state.snackMessage}
      onClose={this.handleSnackClose}
      />
      </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(injectStripe(CheckoutForm));
