import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { CountryRegionData } from "react-country-region-selector";
import CountriesList from "../helpers/countries";

const styles = theme => ({
  root: {
    height: "100vh"
  },
  paper: {
    marginTop: "5%",
    padding: "5%"
  },
  halfWidthText: {
    display: "inline"
  },
  submit: {
    width: "35%",
    marginTop: "3%"
  }
});

class ShippingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      phone: "",
      email: "",
      country: "",
      region: "",
      form:{
          countryWhitelist:["US", "CA", "GB"]
      }
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };
  selectCountry(val) {
    this.setState({ country: val });
  }

  selectRegion(val) {
    this.setState({ region: val });
  }

  handleFormSubmit = event => {
    event.preventDefault();
    const formValues = {
      address: this.state.address,
      address2: this.state.address2,
      city: this.state.city,
      region: this.state.region,
      country:this.state.country[1],
    };
    this.props.onShippingFormSubmit(formValues);
  };

  render() {
    const { classes } = this.props;
    const props = this.props;
    const { country, region } = this.state;
    const filteredCountries = CountryRegionData.filter((country) => {
        if(this.state.form.countryWhitelist && this.state.form.countryWhitelist.includes(country[1])){
            return country;
        }else if(!this.state.form.countryWhitelist){
            return country; //if no whitelist exists just return
        }
    })

    const getRegions = country => {
      if (!country) {
        return [];
      }
      return country[2].split("|").map(regionPair => {
        let [regionName, regionShortCode = null] = regionPair.split("~");
        return {regionName, regionShortCode};
      });
    };

    return (
      <div>
        <Paper className={classes.paper}>
          <Typography variant="h5">Shipping</Typography>
          <form className={classes.form} onSubmit={this.handleFormSubmit}>
            <TextField
              margin="normal"
              required
              onChange={this.handleChange("address")}
              fullWidth
              id="address"
              label="Address"
              name="address"
              autoComplete="address-line1"
              autoFocus
            />
            <TextField
              margin="normal"
              onChange={this.handleChange("address2")}
              fullWidth
              id="address2"
              label="Address Line 2"
              name="address2"
              autoComplete="address-line2"
              autoFocus
            />
            <Grid container spacing={24}>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  required
                  onChange={this.handleChange("city")}
                  fullWidth
                  id="city"
                  label="City"
                  name="city"
                  autoComplete="shipping locality"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="normal"
                  required
                  onChange={this.handleChange("zip")}
                  fullWidth
                  id="zip"
                  label="Postal Code"
                  name="zip"
                  autoComplete="postal-code"
                  autoFocus
                />
              </Grid>

              <Grid item xs>
                <TextField
                  id="country"
                  label="Country"
                  fullWidth
                  required
                  value={this.state.country}
                  autoComplete="shipping country"
                  select
                  onChange={this.handleChange("country")}
                >
                    {/*Whitelist example [US, CA, ] */}
                  {
                    filteredCountries.map((option, index) => (
                        <MenuItem key={option[0]} value={option}>
                          {option[0]}
                        </MenuItem>
                      ))
                }
                </TextField>
              </Grid>
              <Grid item xs>
                <TextField
                  id="region"
                  fullWidth
                  label="Region"
                  required
                  autoComplete="shipping region"
                  value={this.state.region}
                  select
                  onChange={this.handleChange("region")}
                >
                  {getRegions(this.state.country).map((option, index) => (
                    <MenuItem key={option.regionShortCode} value={option.regionShortCode}>
                      {option.regionName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container justify="center">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Continue
              </Button>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  }
}
export default withStyles(styles)(ShippingForm);
