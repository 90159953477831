import React, { Component } from 'react';
import logo from '../../logo.svg';
import axios from 'axios';
import '../../App.css';
import {
  StripeProvider,
  Elements
} from 'react-stripe-elements';
import InjectedCheckoutForm from './MultistepForm';
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3005";
const styles = {
  parent: {
  //  "width": "100vw",
    "height": "100vh",
    "display": "flex",
    "zIndex": -1,
  }
}

class MultistepFormPage extends Component {
  constructor(props){
  super(props);
  this.state ={
    logo:"https://s3-us-west-2.amazonaws.com/instapayments/null_advancearly",
    form: {},
    line_items: []
  }

}


  render() {

    return (


    <div >
      <div className="stripes">
        <div className="stripe s1"></div>
        <div className="stripe s2"></div>
        <div className="stripe s3"></div>
      </div>
      <StripeProvider apiKey={process.env.REACT_APP_PK_KEY}>
        <Elements>
          <InjectedCheckoutForm {...this.props} />
        </Elements>
      </StripeProvider>
      </div>

    );
  }
}

export default MultistepFormPage;
