import React, { Component } from "react";
import logo from "../../logo.svg";
import "../../App.css";
import { injectStripe } from "react-stripe-elements";
import { withStyles } from "@material-ui/core/styles";
import axios from "axios";
import getSymbolFromCurrency from "currency-symbol-map";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import CardSection from "../formSections/CardSection";
import AddressSection from "../formSections/AddressSection";
import CircularProgress from "@material-ui/core/CircularProgress";
import NameSection from "../formSections/NameSection";
import Snackbar from "@material-ui/core/Snackbar";
import ordinal from "ordinal";

import MySnackbarContentWrapper from "../messages/customSnack";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    minWidth: "80%",
    zIndex: "1000"
  },
  paperIframe: {
    minWidth: "80%",
    zIndex: "1000",
    boxShadow: "none",
    paddingBottom: "25%"
  },
  button: {
    minWidth: "45%",
    marginTop: "5%"
  },
  updateButton: {
    minWidth: "40%",
    marginTop: "1%"
  },
  textField: {
    marginTop: "1%"
  },
  textFieldHalf: {
    width: "30%",
    float: "left"
  },
  textFieldQuarter: {
    width: "25%",
    float: "left"
  },
  textFieldInLine: {
    marginTop: "1%"
  },
  quantityField: {
    width: "70%"
    //float:"right"
    //marginRight:"0px"
  },
  middleColumn: {
    //width: "20%"
  },
  itemTable: {
    width: "55%"
  },
  productName: {},
  interval: {
    fontSize: "12px"
  },
  productQuantity: {
    paddingRight: "0px",
    width: "10%",
    display: "table-cell"
  },
  outlinedColor: {
    borderColor: "#0000008a !important"
  }
});

function addhttp(url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
}

class QuantityForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: `https://s3-us-west-2.amazonaws.com/instapayments/${this.props.match.params.orgId}_${this.props.match.params.org}`,
      form: {
        currency: "usd"
      },
      line_items: [],
      editable_line_items: [],
      custom_fields: [],
      metadata: {},
      height: window.innerHeight,
      width: window.innerWidth,
      firstLoadAttempted: false
    };
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.setState(this.props.passedData);
    var self = this;
  }

  showNotification = (
    message = "no message recieved in notification function",
    type = "error"
  ) => {
    this.setState({
      snackOpen: true,
      snackVariant: type,
      snackMessage: message
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleQuantityChange = id => event => {
    const unmutatedArray = [...this.state.editable_line_items];
    let quantity = event.target.value;
    let index = unmutatedArray.findIndex(item => item.id == id);
    unmutatedArray[index].quantity = Number(quantity);
    this.setState({
      editable_line_items: unmutatedArray
    });
  };

  handleCustomFieldChange = name => event => {
    const existingMetaData = { ...this.state.metadata };
    existingMetaData[name] = event.target.value; //remake the metadata object with a new value
    this.setState({
      metadata: existingMetaData
    });
  };

  handlePassChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  handleSubmit = ev => {
    ev.preventDefault();
    var self = this;
    let filteredProducts = self.state.editable_line_items.filter(
      item => item.quantity > 0
    );

    if (!this.state.cardCompleted) {
      self.setState({
        submitting: false,
        snackOpen: true,
        snackVariant: "error",
        snackMessage: "Please check your card"
      });
    } else if (filteredProducts.length === 0) {
      self.setState({
        snackOpen: true,
        snackVariant: "error",
        snackMessage:
          "Make sure you have at least one item with a quantity greater than 0."
      });
    } else {
      console.log("product length was 0");
      completeCheckout();
    }

    function completeCheckout() {
      self.setState({
        submitting: true,
        filtered_editable_line_items: filteredProducts
      });

      let otherData = {
        email: self.state.email,
        name: self.state.name,
        address: self.state.address,
        city: self.state.city,
        country: self.state.country,
        state: self.state.state,
        postal_code: self.state.postal_code,
        zip: self.state.postal_code,
        metadata: self.state.metadata
      };
      if (self.state.coupon) {
        otherData.coupon = self.state.coupon;
      }

      self.props.stripe
        .createToken({
          name: self.state.name,
          address_line1: self.state.address,
          address_city: self.state.city,
          address_country: self.state.country,
          address_state: self.state.state,
          address_zip: self.state.postal_code
        })
        .then(({ token }) => {
          if (token) {
            if (self.state.customerId && self.state.invoiceId) {
              self.handleIncomplete(token, otherData);
            } else {
              self.postToken(token, otherData);
            }
          } else {
            self.setState({
              submitting: false,
              snackOpen: true,
              snackVariant: "error",
              snackMessage: "Please check your card"
            });
          }
        });
    }
  };

  postToken = (token, otherData) => {
    var self = this;

    axios
      .post(
        `${apiBaseUrl}/v2/form/forms/${self.props.match.params.formId}${
          self.props.testMode ? "?test=true" : ""
        }`,
        {
          token: token.id,
          full_token: token,
          email: otherData.email,
          name: otherData.name,
          address: otherData.address,
          city: otherData.city,
          country: otherData.country,
          state: otherData.state,
          postal_code: otherData.postal_code,
          zip: otherData.postal_code,
          metadata: otherData.metadata,
          coupon: otherData.coupon,
          line_items: self.state.filtered_editable_line_items //for v2 forms we can simply change the name passed to the backend
        }
      )
      .then(function(response) {
        self.handleSubmitSuccess(response);
      })
      .catch(function(error) {
        self.handleCardDeclineResponse(error);
      });
  };

  /*Handles a card decline with new payment intent API */
  handleIncomplete = (token, otherData) => {
    var self = this;
    axios
      .post(`${apiBaseUrl}/v2/form/forms/transaction/incomplete`, {
        token: token.id,
        org: self.state.org_id,
        customerId: self.state.customerId,
        invoiceId: self.state.invoiceId,
        full_token: token,
        email: otherData.email,
        name: otherData.name,
        address: otherData.address,
        city: otherData.city,
        country: otherData.country,
        state: otherData.state,
        postal_code: otherData.postal_code,
        zip: otherData.postal_code,
        metadata: otherData.metadata,
        coupon: otherData.coupon
      })
      .then(function(response) {
        self.handleSubmitSuccess(response);
      })
      .catch(function(error) {
        self.handleCardDeclineResponse(error);
      });
  };

  /*Triggers the extra authentication for use cases that need SCA*/
  handleSCA = requestBody => {
    var self = this;
    self.showNotification("Additional verification is required.", "warning");
    self.props.stripe
      .handleCardPayment(requestBody.pi_secret, self.state.cardElement, {
        payment_method_data: {
          billing_details: {
            name: self.state.name
          }
        }
      })
      .then(function(result) {
        console.log(result);
        if (
          result.paymentIntent &&
          result.paymentIntent.status === "succeeded"
        ) {
          console.log("the payment succeeded");
          self.showNotification("The payment was successful.", "success");
          let url = "/success";
          let queryParams = `?customer_id=${requestBody.customer_id}&subscription_id=${requestBody.subscription_id}&plan_id=${requestBody.plan}&product_id=${requestBody.product}`;

          if (self.state.form.redirect_url) {
            url = addhttp(self.state.form.redirect_url);
          }

          window.top.location.href = url + queryParams;
        } else if (result.error) {
          console.log("ERROR AFTER SCA", result);
          self.setState({
            submitting: false
          });
          if (result.error && result.error.message) {
            self.showNotification(result.error.message);
          } else {
            self.showNotification(
              "There was an error after additional verification."
            );
          }
        } else {
          self.setState({
            submitting: false
          });
          self.showNotification(
            "Error handling additional verification response."
          );
        }
        // Handle result.error or result.paymentIntent
      })
      .catch(err => {
        self.setState({
          submitting: false
        });
        self.showNotification("Error triggering response.");
        console.log(err);
      });
  };

  /*Deals with the various ways an error can be returned*/
  handleCardDeclineResponse = error => {
    var self = this;
    console.log("ERROR STATUS", error.response);

    let errorResponse =
      "Error processing. Please check your card and other fields.";
    if (error.response && error.response.data && error.response.data.message) {
      errorResponse =
        error.response && error.response.data && error.response.data.message;

      const requestBody = error.response.data;
      if (
        error.response.status === 406 &&
        requestBody.pi_status === "requires_action"
      ) {
        self.handleSCA(requestBody); //handle the additional verification if our backend returns 406
      } else if (
        error.response.status === 406 &&
        requestBody.pi_status === "requires_payment_method"
      ) {
        /*save the customer ID and invoice ID to state and ask for a new card*/
        self.setState({
          submitting: false,
          customerId: error.response.data.customer_id,
          invoiceId: error.response.data.invoice_id
        });
        self.showNotification(errorResponse);
      } else {
        self.setState({
          submitting: false
        });
        self.showNotification(errorResponse);
      }
      console.log(error);
    } else {
      /*This is a more generic card failure*/
      self.setState({
        submitting: false
      });
      self.showNotification(errorResponse);
    }
  };
  /*Triggered when a charge is successfully completed. Redirects to the url set by the business.*/
  handleSubmitSuccess = response => {
    var self = this;
    self.setState({
      submitting: false
    });
    let url = "/success";
    let queryParams = `?customer_id=${response.data.customer_id}&subscription_id=${response.data.subscription_id}&plan_id=${response.data.plan}&product_id=${response.data.product}`;
    if (self.state.form.redirect_url) {
      function addhttp(url) {
        if (!/^(f|ht)tps?:\/\//i.test(url)) {
          url = "http://" + url;
        }
        return url;
      }
      url = addhttp(self.state.form.redirect_url);
    }
    //window.location.href = url;
    window.top.location.href = url + queryParams;
  };

  fetchDiscount = () => {
    var self = this;
    if (self.state.coupon && self.state.coupon.length > 1) {
      axios
        .get(
          `${apiBaseUrl}/v1/form/discounts/${self.state.org_id}?coupon=${self.state.coupon}`
        )
        .then(function(response) {
          self.setState({
            coupon_response: response.data.coupon,
            coupon_invalid: false
          });
        })
        .catch(function(error) {
          self.setState({
            coupon_invalid: true,
            coupon_response: undefined
          });
          self.showNotification("That coupon looks invalid.");
        });
    } else {
      self.setState({
        coupon_invalid: false,
        coupon_response: undefined
      });
    }
  };

  handleCardReady = element => {
    this.setState({ cardElement: element });
  };

  handleCardChange = event => {
    if (event.error) {
      this.setState({
        snackOpen: true,
        snackVariant: "error",
        snackMessage: event.error.message
      });
    } else if (event.error === undefined) {
      this.setState({
        snackOpen: false
      });
    }

    if (event.complete) {
      this.setState({
        cardCompleted: true
      });
    }
  };

  handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackOpen: false });
  };

  fetchDiscount = () => {
    var self = this;
    if (self.state.coupon && self.state.coupon.length > 1) {
      axios
        .get(
          `${apiBaseUrl}/v1/form/discounts/${self.props.match.params.orgId}?coupon=${self.state.coupon}`
        )
        .then(function(response) {
          self.setState({
            coupon_response: response.data.coupon,
            coupon_invalid: false
          });
        })
        .catch(function(error) {
          self.setState({
            snackOpen: true,
            snackVariant: "error",
            snackMessage: "That coupon looks invalid.",
            coupon_invalid: true,
            coupon_response: undefined
          });
        });
    } else {
      self.setState({
        coupon_invalid: false,
        coupon_response: undefined
      });
    }
  };

  render() {
    console.log(this.state);
    const { classes } = this.props;
    const lineItems = [];

    let total = 0;
    const customFields = [];

    for (let item of this.state.editable_line_items) {
      let lineItemDesc = ` (every ${
        item.interval_count === 1 ? "" : item.interval_count
      } ${item.interval})`;
      if (
        this.state.form.billing_anchor_date &&
        this.state.form.billing_anchor_date.active && 
        this.state.form.billing_anchor_date.prorated === false
      ) {
        lineItemDesc = ` starting on the ${ordinal(this.state.form.billing_anchor_date.date_number)}`
      }else{
        total = total + item.price * item.quantity;
      }

      lineItems.push(
        <tr key={item.id} className="line-item line-product">
          <td>
            {" "}
            <TextField
              required
              label={item.name}
              value={item.quantity}
              onChange={this.handleQuantityChange(item.id)}
              className={classes.quantityField}
              margin="normal"
              type="number"
              variant="outlined"
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedColor
                }
              }}
            />
          </td>
          <td className={classes.middleColumn}>x</td>
          <td>
            {`${getSymbolFromCurrency(this.state.form.currency)}${(
              (item.price * item.quantity) /
              100
            ).toFixed(2)}`}
            <span className={classes.interval}>{lineItemDesc}</span>
          </td>
        </tr>
      );
      
    }

    if (
      this.state.form &&
      this.state.form.setup_fee &&
      this.state.form.setup_fee.active
    ) {
      total = total + Number(this.state.form.setup_fee.amount_in_cents);
      lineItems.push(
        <tr className="line-item line-product">
          <td>Initial Fee</td>
          <td className={classes.middleColumn} />
          <td>{`${getSymbolFromCurrency(this.state.form.currency)}${(
            Number(this.state.form.setup_fee.amount_in_cents) / 100
          ).toFixed(2)}`}</td>
        </tr>
      );
    }
    if (this.state.form && this.state.form.custom_fields) {
      for (let custom_field of this.state.custom_fields) {
        if (custom_field.field_type === "dropdown") {
          customFields.push(
            <TextField
              select
              label={custom_field.name}
              fullWidth
              required={custom_field.required}
              value={this.state.metadata[custom_field.name]}
              onChange={this.handleCustomFieldChange(custom_field.name)}
              SelectProps={{
                native: true,
              }}
            >
              <option value="" />
              {custom_field.values.map(value => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </TextField>
          );
        } else if (custom_field.field_type === "text") {
          customFields.push(
            <TextField
              key={custom_field._id}
              label={custom_field.name}
              fullWidth
              className={classes.textField}
              required={custom_field.required}
              onChange={this.handleCustomFieldChange(custom_field.name)}
            />
          );
        }
      }
    }

    if (
      this.state.form &&
      this.state.form.taxes &&
      this.state.form.taxes.active
    ) {
      lineItems.push(
        <tr className="line-item line-product">
          <td>
            Taxes {Math.round(this.state.form.taxes.percent * 100) / 100}%
          </td>
          <td className={classes.middleColumn} />
          <td>
            {getSymbolFromCurrency(this.state.form.currency)}
            {(
              total *
              (Number(this.state.form.taxes.percent) / 100 / 100)
            ).toFixed(2)}
          </td>
        </tr>
      );

      total = total + total * (Number(this.state.form.taxes.percent) / 100); //this must be last so the tax line item calculates the right amount
    }

    if (
      this.state.form &&
      this.state.form.installment_plan &&
      this.state.form.installment_plan.active
    ) {
      lineItems.push(
        <tr className="line-item line-product">
          <td>Expiration</td>
          <td>
            <span
              className={classes.interval}
            >{`Expires after a total of ${getSymbolFromCurrency(
              this.state.form.currency
            )}${this.state.form.installment_plan.amount_in_cents /
              100} is charged.`}</span>
          </td>
        </tr>
      );

      total = total + total * (Number(this.state.form.taxes.percent) / 100); //this must be last so the tax line item calculates the right amount
    }

    if (this.state.coupon_response) {
      let discount_amount = 0;
      if (this.state.coupon_response.amount_off) {
        discount_amount = this.state.coupon_response.amount_off;
        total = total - this.state.coupon_response.amount_off;
      }

      if (this.state.coupon_response.percent_off) {
        discount_amount =
          total * (this.state.coupon_response.percent_off / 100);
        total = total - discount_amount;
      }

      lineItems.push(
        <tr className="line-item line-product">
          <td>Coupon</td>
          <td className={classes.middleColumn}>-</td>
          <td>
            {`${getSymbolFromCurrency(this.state.form.currency)}${(
              Number(discount_amount) / 100
            ).toFixed(2)}`}
            <span className={classes.interval}>{" " + this.state.coupon}</span>
          </td>
        </tr>
      );
    }

    return (
      <div className={"child"}>
        {this.state.firstLoadAttempted ? (
          <div>
            <Paper
              className={
                this.state.width < 500 ? classes.paperIframe : classes.paper
              }
            >
              <Grid container justify="center">
                <div style={{ maxHeight: "3%", marginTop: "1%" }}>
                  {this.state.form &&
                  this.state.form.custom_logo &&
                  this.state.form.custom_logo.active ? (
                    <img
                      src={this.state.form.custom_logo.custom_logo_url}
                      style={{ maxHeight: "120px" }}
                      alt="logo"
                    />
                  ) : this.state.logo ? (
                    <img
                      src={this.state.logo}
                      style={{ maxHeight: "120px" }}
                      alt="logo"
                    />
                  ) : null}
                </div>
              </Grid>
              <Grid container justify="center">
                <div id="item-table">
                  <table className="itemTable">
                    <tbody>{lineItems}</tbody>
                    <tfoot>
                      <tr className="line-item total">
                        <td>
                          <strong>TOTAL</strong>
                        </td>
                        <td className={classes.middleColumn} />
                        <td>
                          <span id="checkout-total">
                            {getSymbolFromCurrency(this.state.form.currency)}
                            {(total / 100).toFixed(2)}
                          </span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <Divider />
                </div>
              </Grid>
              <form className={"form"} onSubmit={this.handleSubmit}>
                <Grid container spacing={0} justify="center">
                  <NameSection
                    {...this.props}
                    custom_labels={this.state.form.custom_labels}
                    changeInput={this.handlePassChange}
                  />
                  {this.state.form.hide_address ? null : (
                    <AddressSection
                      {...this.props}
                      custom_labels={this.state.form.custom_labels}
                      changeInput={this.handlePassChange}
                    />
                  )}
                  {this.state.form && this.state.form.coupon_enabled ? (
                    <TextField
                      label="Promo Code"
                      fullWidth
                      error={this.state.coupon_invalid}
                      onBlur={this.fetchDiscount}
                      className={classes.textField}
                      onChange={this.handleChange("coupon")}
                    />
                  ) : null}
                  {customFields}
                  <Grid item xs={12}>
                    <CardSection
                      {...this.props}
                      onCardReady={this.handleCardReady}
                      onCardChange={this.handleCardChange}
                    />
                  </Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth={this.state.width < 500 ? true : false}
                    style={{
                      backgroundColor: this.state.primary_color || "#814ff8",
                      position: this.state.width < 500 ? "fixed" : undefined,
                      bottom: this.state.width < 500 ? 0 : undefined,
                      padding: this.state.width < 500 ? "7%" : undefined
                    }}
                    className={classes.button}
                    disabled={this.state.submitting}
                    type="submit"
                  >
                    {this.state.submitting ? (
                      <CircularProgress
                        className={classes.progress}
                        style={{
                          backgroundColor: "#fff",
                          color: "#fff"
                        }}
                      />
                    ) : (
                      (this.state.form.custom_labels &&
                        this.state.form.custom_labels.checkout_button) ||
                      "Complete Checkout"
                    )}
                  </Button>
                </Grid>
              </form>
            </Paper>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={this.state.snackOpen}
              autoHideDuration={2000}
              onClose={this.handleSnackClose}
            >
              <MySnackbarContentWrapper
                variant={this.state.snackVariant}
                message={this.state.snackMessage}
                onClose={this.handleSnackClose}
              />
            </Snackbar>
          </div>
        ) : (
          <Grid container justify={"center"}>
            <div className={classes.loader}>
              <CircularProgress size={100} className={classes.progress} />
            </div>
          </Grid>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(injectStripe(QuantityForm));
