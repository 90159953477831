import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const styles = theme => ({
    root: {
      height: "100vh"
    },
    paper:{
        marginTop: "10%",
       marginLeft: "2%",
        padding: "5%",
        minHeight: 300
        //width: "50%"
    },
    summaryTable:{
      "marginLeft":"4%"
    },
    table:{
      width: "100%"
    },
    tableItems:{
      textAlign: "left"
    }
  });

  

function BillingSummary(props) {
  const { classes } = props;
  return (
      <Paper className={classes.paper}>
          <Typography variant="h6">
          Billing Summary
          </Typography>
        <div className={classes.summaryTable}>
        <table className={classes.table}>
            <tr>
                <td className={classes.table}>Glasses x 1</td>
                <td className={classes.table}>$10</td>
            </tr>
            <tr>
                <td className={classes.table}>Lenses x 1</td>
                <td className={classes.table}>$10</td>
            </tr>
            <tr>
                <td className={classes.table}>Contacts x 1</td>
                <td className={classes.table}>$10</td>
            </tr>
        
        </table>
        <Divider/>
        <table className={classes.table}>
            <tr>
                <td className={classes.table}><strong>Total</strong></td>
                <td className={classes.table}><strong>$30</strong></td>
            </tr>
        </table>
        </div>
        </Paper>
  );
}

BillingSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BillingSummary);