import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { CountryRegionData } from "react-country-region-selector";

class CountriesList extends React.Component {
  render() {
    return (
      <React.Fragment>
        {CountryRegionData.map((option, index) => (
          <MenuItem key={option[0]} value={option}>
            {option[0]}
          </MenuItem>
        ))}
      </React.Fragment>
    );
  }
}

export default CountriesList;
