import React from "react";
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from "react-stripe-elements";
import Grid from "@material-ui/core/Grid";

const style = {
  cardNumber: {
    base: {
      fontSize: "17px",
      width: "100%",
      padding: "10px"
    }
  },
  cardNumberDiv: {
    paddingTop: "2%"
  }
};

class CardSection extends React.Component {
  render() {
    const temp = [
      {
        /*<Grid item xs={3}>
          <CardNumberElement style={style.cardNumber}/>
        </Grid>
        <Grid item xs={3}>
        <CardExpiryElement style={{base: {fontSize: '18px'}}}/>
        </Grid>
        <Grid item xs={3}>
        <CardCVCElement style={{base: {fontSize: '18px'}}}/>
        </Grid>*/
      }
    ];

    return (
      <div style={{ 
        marginTop: "3%",
        borderBottom: "1px solid #949494",
        paddingBottom: 3
    }}>
        <CardElement
          style={style.cardNumber}
          onChange={event => this.props.onCardChange(event)}
          onReady={event => this.props.onCardReady(event)}
        />
      </div>
    );
  }
}

export default CardSection;
