import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";
let url = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

let StripeCheckout = () => {
  let { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      try {
        let { data } = await axios.get(`${url}/v2/org/checkouts/${id}`);
        let stripe = window.Stripe(process.env.REACT_APP_PK_KEY, {
          stripeAccount: data.org.stripe_credentials.stripe_user_id,
        });
        stripe.redirectToCheckout({
            sessionId: data.stripeCheckout.id
        })
        console.log(data);
      } catch (e) {
        cogoToast.error("There was an error loading this checkout");
      }
    }
    fetchData();
  });
  return <div>Checkout</div>;
};

export default StripeCheckout;
