import React, { Component } from "react";
import logo from "../../logo.svg";
import axios from "axios";
import "../../App.css";
import { StripeProvider, Elements } from "react-stripe-elements";
import InjectedCheckoutForm from "./UpdatePaymentForm";
const apiBaseUrl = process.env.REACT_APP_BASE_URL || "http://localhost:3000";
const styles = {
  parent: {
    //"width": "100vw",
    height: "100vh",
    display: "flex",
    zIndex: -1
  }
};

class UpdatePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    var self = this;
  axios.get(`${apiBaseUrl}/v1/form/updates/${self.props.match.params.org}/${self.props.match.params.orgId}/${self.props.match.params.customerId}/${self.props.match.params.passedHash}`)
  .then(function (response) {
    if (!response.data.has_subscription) {
      window.location.href = "/canceled";
    }else{

      self.setState({
       ...response.data
      });
    }

  })
  .catch((error) => {
    self.setState({
      submitting: false,
      snackOpen: true,
      snackVariant:"error",
      snackMessage:"Error loading form."
    })
    console.log(error);
  });
  }

  render() {
    return (
      <div style={styles.parent}>
        <div className="stripes">
          <div className="stripe s1" />
          <div className="stripe s2" />
          <div className="stripe s3" />
        </div>
        {/*process.env.REACT_APP_PK_TEST_KEY*/}
        {this.state.stripe_account ? (
          <StripeProvider
            apiKey={process.env.REACT_APP_PK_KEY}
            stripeAccount={this.state.stripe_account}
          >
            <Elements>
              <InjectedCheckoutForm testMode={false} passedData={this.state} {...this.props} />
            </Elements>
          </StripeProvider>
        ) : null}
      </div>
    );
  }
}

export default UpdatePage;

