import React from "react";
import { CardElement } from "react-stripe-elements";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import queryString from "query-string";
import Avatar from "@material-ui/core/Avatar";

const styles = theme => ({});

class NameSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email: ""
    };
  }
  componentDidMount() {
    this.prePopulate();
  }

  prePopulate = async () => {
    const { first_name, last_name, email } = queryString.parse(
      this.props.location.search
    );

    await this.setState({
      first_name: first_name,
      last_name: last_name,
      email: email
    });

    this.props.changeInput("name", first_name + " " + last_name);
    this.props.changeInput("email", email);
  };

  passChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
    this.props.changeInput(name, event.target.value);
  };
  passNameChange = name => async event => {
    await this.setState({
      [name]: event.target.value
    });
    var first_name = this.state.first_name || "";
    var last_name = this.state.last_name || "";
    this.props.changeInput("name", first_name + " " + last_name);
  };

  render() {
    const { classes, custom_labels } = this.props;
    return (
      <React.Fragment>
        <Grid item md={6} xs={12}>
          <TextField
            label={(custom_labels && custom_labels.first_name) || "First Name"}
            fullWidth
            value={this.state.first_name}
            required
            onChange={this.passNameChange("first_name")}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label={(custom_labels && custom_labels.last_name) || "Last Name"}
            fullWidth
            value={this.state.last_name}
            required
            onChange={this.passNameChange("last_name")}
          />
        </Grid>

        <Grid item xs={12}>
          {/*  <TextField
            label="Name"
            fullWidth
            required
            className={classes.textField}
            onChange={this.passChange("name")}
        />*/}
          <TextField
            label={(custom_labels && custom_labels.email) || "Email"}
            fullWidth
            value={this.state.email}
            required
            type="email"
            onChange={this.passChange("email")}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

export default NameSection;
