import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";
let url = process.env.REACT_APP_BASE_URL || "http://localhost:3000";

let CheckoutUpdatePage = () => {
  let { orgId, passedHash } = useParams();
  useEffect(() => {
    async function fetchData() {
      try {
        let { data: session } = await axios.get(
          `${url}/v2/org/checkouts/update/${orgId}/${passedHash}`
        );

        console.log(session);
        window.location.href = session.url;
      } catch (e) {
        cogoToast.error("There was an error loading this update page.");
      }
    }
    fetchData();
  });
  return <div>Checkout</div>;
};

export default CheckoutUpdatePage;
